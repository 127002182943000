import React, { useState, useEffect } from 'react';
import userService from '../../services/userService';
import Spin from '../../components/Spin';
import ShowDeveloper from '../developers/Show';
import ShowClient from '../clients/Show';
import ShowClientTenant from '../clientsTenants/Show';
import ShowTenant from '../tenants/Show';
import ShowWorker from '../workers/Show';

const Show = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, []);

  const headerTitle = 'Moje konto';
  let component = '';
  if (userData?.id) {
    if (userData.roles.includes('ROLE_DEVELOPER')) {
      component = <ShowDeveloper customUserData={userData} customTitle={headerTitle} />;
    } else if (userData.roles.includes('ROLE_TENANT')) {
      component = <ShowTenant customUserData={userData} customTitle={headerTitle} />;
    } else if (userData.roles.includes('ROLE_CLIENT')) {
      component = <ShowClient customUserData={userData} customTitle={headerTitle} />;
    } else if (userData.roles.includes('ROLE_CLIENT_TENANT')) {
      component = <ShowClientTenant customUserData={userData} customTitle={headerTitle} />;
    } else if (userData.roles.includes('ROLE_WORKER')) {
      component = <ShowWorker customUserData={userData} customTitle={headerTitle} />;
    }
  }

  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        component
      )}
    </div>
  );
};

export default Show;
