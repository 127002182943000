import React, { useState, useEffect } from 'react';
import rodoService from '../../services/rodoService';
import Spin from '../../components/Spin';

const Rodo = () => {
  const [loading, setLoading] = useState(true);
  const [rodoContent, setRodoContent] = useState(null);
  useEffect(() => {
    rodoService.verify().then((data) => {
      setRodoContent(data.content);
      setLoading(false);
    });
  }, []);

  return (
    loading ? (
      <div className="loading"><Spin /></div>
    ) : (
      <div className="rodo-content">{rodoContent}</div>
    )
  );
};

export default Rodo;
