/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import {
  Input as AntdInput, DatePicker, Select, InputNumber, Spin, Switch,
} from 'antd';
import debounce from 'lodash/debounce';
import MaskedInput from 'antd-mask-input';

import FloatLabel from './FloatingLabel';
import Empty from './Empty';

const { TextArea } = AntdInput;

const Input = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <AntdInput {...props} placeholder="" />
    </FloatLabel>
  );
};

Input.Password = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <AntdInput {...props} type="password" placeholder="" />
    </FloatLabel>
  );
};

Input.MaskedInput = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <MaskedInput {...props} placeholder="" />
    </FloatLabel>
  );
};

Input.DatePicker = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <DatePicker {...props} placeholder="" />
    </FloatLabel>
  );
};

Input.Select = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Select {...props} placeholder="" />
    </FloatLabel>
  );
};

Input.TextArea = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <TextArea {...props} placeholder="" />
    </FloatLabel>
  );
};

Input.InputNumber = (props) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <InputNumber {...props} placeholder="" style={{ width: '100%' }} />
    </FloatLabel>
  );
};

Input.InputNumberV2 = (props) => {
  const {
    label, value, id, placeholder,
  } = props;

  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        type="number"
        placeholder=""
        step="0.01"
        style={{ width: '100%' }}
        // onChange={onChange}
      />
    </FloatLabel>
  );
};

Input.PriceInput = (props) => {
  const {
    label, value, id, placeholder, suffix,
  } = props;

  const customLabel = label || placeholder || '';
  const inputSuffix = suffix || 'zł brutto';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        type="number"
        suffix={inputSuffix}
        placeholder=""
        step="0.01"
        style={{ width: '100%' }}
      />
    </FloatLabel>
  );
};

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (val) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(val).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  React.useEffect(() => {
    setOptions([]);
    setFetching(true);
    fetchOptions().then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, [fetchOptions]);
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Select
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        placeholder=""
        options={options}
        style={{
          width: '100%',
        }}
      />
    </FloatLabel>
  );
};

const SearchBox = ({
  resource,
  filters = {},
  methodName = 'getList', resourceField, optionKey = 'id', optionValue = 'name', additionalFirstOption, debounceTimeout = 800, ...props
}) => {
  const {
    label, value, id, placeholder,
  } = props;
  const customLabel = label || placeholder || '';
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (val) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const requestParams = filters;
      if (val) {
        if (Array.isArray(resourceField)) {
          resourceField.forEach((fieldName) => {
            requestParams[fieldName] = val;
          });
        } else {
          requestParams[resourceField] = val;
        }
      }

      resource[methodName](requestParams).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        let optionsValue = newOptions;
        if (!Array.isArray(newOptions)) {
          optionsValue = newOptions.content;
        }
        setOptions(optionsValue);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [resource, filters, resourceField, debounceTimeout, methodName]);

  useEffect(() => {
    setOptions([]);

    if (!resource) {
      return;
    }

    setFetching(true);
    resource[methodName]({
      ...filters,
    }).then((newOptions) => {
      let optionsValue = newOptions;
      if (!Array.isArray(newOptions)) {
        optionsValue = newOptions.content;
      }
      setOptions(optionsValue);
      setFetching(false);
    });
  }, [resource, filters, methodName]);

  const renderOptions = options.map((d) => {
    let renderValue = optionValue;
    if (typeof optionValue === 'function') {
      renderValue = optionValue(d);
    } else {
      renderValue = d[optionValue];
    }
    return (
      <Select.Option key={d[optionKey]}>{renderValue}</Select.Option>
    );
  });

  if (additionalFirstOption) {
    renderOptions.unshift(additionalFirstOption);
  }

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Select
        showSearch
        allowClear
        {...props}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
        placeholder=""
        style={{
          width: '100%',
        }}
      >
        {renderOptions}
      </Select>
    </FloatLabel>
  );
};

Input.DebounceSelect = DebounceSelect;
Input.SearchBox = SearchBox;
Input.Switch = Switch;
Input.Select.Option = Select.Option;

export default Input;
