import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import tagsService from '../../services/tagsService';
import userService from '../../services/userService';
import Footer from '../../helpers/Footer';
import { Spin, Input } from '../../components';

const { Select } = Input;
const { Option } = Select;

const Popup = (props) => {
  const {
    data, afterSave, afterCancel,
  } = props;

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    userService.getInfo().then((response) => {
      setUserData(response);
      setLoading(false);
    });
  }, []);

  if (data?.id) {
    form.setFieldsValue(data);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (data.id) {
          tagsService.update(data.id, values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          tagsService.create(values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? `Edytujesz: ${data.name}` : 'Dodaj nowy tag';

  const { isModalVisible } = props;
  const { roles } = userData;
  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        {loading ? (
          <div className="loading"><Spin /></div>
        ) : (
          <Form
            form={form}
            name="filterDeveloper"
            className="filters-developer-form"
            onFinish={onFinish}
          >
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Nazwa" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Select
                    placeholder="Grupa"
                    allowClear
                    size="large"
                  >
                    {!roles.includes('ROLE_TENANT') && (
                      <Option value="TENANT_CHANGE">
                        Zmiany lokatorskie
                      </Option>
                    )}
                    <Option value="FAULT">
                      Usterki
                    </Option>
                    <Option value="PREMISES">
                      Lokale
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default Popup;
