import React, { useEffect, useState } from 'react';
import {
  Button, PageHeader, Row, Col, Card,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import PaymentsServiceS from '../../services/paymentsService';
import TPayS from '../../services/tpayService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';

const Show = () => {
  const {
    id, premisesId, investmentId, agreementId,
  } = useParams();

  const history = useHistory();
  const [methods, setMethods] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const PaymentsService = new PaymentsServiceS(investmentId, premisesId, agreementId, null);
    PaymentsService.getPaymentMethods().then((response) => {
      setMethods(response);
      setLoading(false);
    });
  }, [id, premisesId, investmentId, agreementId]);

  const PaymentMethod = ({ method, selectHandle, currentId }) => (
    <Col xs={12} sm={8} lg={6} xl={4}>
      <div
        role="presentation"
        className={`method ${currentId === method.id ? 'active' : ''}`}
        onClick={() => {
          selectHandle(method.id);
        }}
      >
        <img src={method.img} alt={method.name} />
      </div>
    </Col>
  );

  const redirectToPaymentMethod = () => {
    const TpayService = new TPayS();
    TpayService.getTpayResponse(id, selectedId).then((response) => {
      if (response?.url) {
        window.location.href = response.url;
      }
    }).catch((err) => console.log(err));
  };

  const selectMethod = (value) => {
    setSelectedId(value);
  };

  const goBack = () => {
    if (premisesId && investmentId && agreementId) {
      return history.push('/payments');
    }
    return history.push('/packagePayments');
  };

  return (
    <div className="form-create">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => goBack()}
          title="Wybierz metodę płatności"
        >
          <>
            <Card className="form-create-main-card">
              <div className="methods">
                <Row gutter={20}>
                  {Object.keys(methods).map((key) => (
                    <PaymentMethod
                      key={key}
                      method={methods[key]}
                      selectHandle={selectMethod}
                      currentId={selectedId}
                    />
                  ))}
                </Row>

                <div style={{ textAlign: 'center' }}>
                  <Button type="primary" disabled={!selectedId} size="large" onClick={() => redirectToPaymentMethod()}>Przejdź do płatności</Button>
                </div>
              </div>

            </Card>
          </>
        </PageHeader>
      )}

    </div>
  );
};
export default Show;
