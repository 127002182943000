import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, Switch, message,
} from 'antd';
import packagesService from '../../services/packagesService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import Input from '../../components/InputComponent';

const { Select, PriceInput } = Input;
const { Option } = Select;

const Edit = () => {
  const { id } = useParams();
  const [packageData, setPackageData] = useState({});

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    packagesService.getOne(id).then((data) => {
      setPackageData(data);
      form.setFieldsValue(data);
    });
  }, [form, id]);

  const onFinish = (values) => {
    packagesService.update(id, values).then((response) => {
      if (response.id) {
        history.push(`/packages/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    packagesService.delete(id).then(() => history.push('/packages'));
  };

  const handleCancel = () => {
    history.push('/packages');
  };

  const HeaderTitle = () => {
    if (!packageData.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{packageData.name}</span>
      </span>
    );
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/packages')}
        title={<HeaderTitle />}
        extra={[
          <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
            <Icon name="delete" style={{ marginRight: '5px' }} />
            {' '}
            Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <div className="create-form-wrapper">
                <Row>
                  <Col xs={20} offset={2}>
                    <div className="form-section">
                      <h2>Dane pakietu</h2>
                      <Row gutter={20}>
                        <Col xs={12}>
                          <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Input size="large" placeholder="Nazwa pakietu" />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            name="price"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <PriceInput size="large" placeholder="Cena" suffix="zł netto" />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            name="type"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Typ pakietu"
                              size="large"
                            >
                              <Option value="DEVELOPER">Deweloper</Option>
                              <Option value="RENT">Najemca</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            name="status"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Status"
                              size="large"
                            >
                              <Option value="ACTIVE">Aktywny</Option>
                              <Option value="INACTIVE">Nieaktywny</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            name="color"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Input size="large" placeholder="Kolor" />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            name="availableForUser"
                            label="Dostępne dla użytkowników"
                            valuePropName="checked"
                          >
                            <Switch size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <h2>Limity</h2>
                    <Row gutter={20}>
                      <Form.Item
                        noStyle
                          // eslint-disable-next-line max-len
                        shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                      >
                        {({ getFieldValue }) => getFieldValue('type') === 'RENT' && (
                        <Col xs={12}>
                          <Form.Item
                            name={['limits', 'premisesRent']}
                          >
                            <Input size="large" placeholder="Ilość mieszkań i lokali" />
                          </Form.Item>
                        </Col>
                        )}
                      </Form.Item>
                      <Form.Item
                        noStyle
                          // eslint-disable-next-line max-len
                        shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                      >
                        {({ getFieldValue }) => getFieldValue('type') === 'DEVELOPER' && (
                          <>
                            <Col xs={12}>
                              <Form.Item
                                name={['limits', 'investments']}
                              >
                                <Input size="large" placeholder="Ilość inwestycji" />
                              </Form.Item>
                            </Col>
                            <Col xs={12}>
                              <Form.Item
                                name={['limits', 'premises']}
                              >
                                <Input size="large" placeholder="Ilość mieszkań" />
                              </Form.Item>
                            </Col>
                            <Col xs={12}>
                              <Form.Item
                                name={['limits', 'service']}
                              >
                                <Input size="large" placeholder="Ilość lokali usługowych" />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.Item>
                      <Col xs={12}>
                        <Form.Item
                          name={['limits', 'parking']}
                        >
                          <Input size="large" placeholder="Ilość miejsc postojowych" />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name={['limits', 'storage']}
                        >
                          <Input size="large" placeholder="Ilość komórek lokatorskich" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="attachmentsNote" style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>
                      Bez ograniczeń: wpisz -1.
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
