import React, { useState, useRef } from 'react';
import {
  Button, Col, Form,
} from 'antd';
import Datagrid from '../../components/Datagrid';
import Icon from '../../components/Icon';
import tagsTypeMap from '../../helpers/tagsTypeMap';
import tagsService from '../../services/tagsService';
import Popup from './Popup';
import Input from '../../components/InputComponent';

const { Select } = Input;
const { Option } = Select;
const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: false,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Grupa',
    dataIndex: 'type',
    visible: true,
    filterEnabled: false,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => tagsTypeMap(record.type),
    exportRender: (text, record) => tagsTypeMap(record.type),
  },
];

const List = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const datagridRef = useRef();

  const afterSave = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const runModal = (record) => {
    if (record?.id) {
      tagsService.getOne(record.id).then((response) => {
        setModalData(response);
        setShowModal(true);
      });
    } else {
      setModalData({});
      setShowModal(true);
    }
  };

  const customEdit = (record) => (
    <Button className="button-no-border" onClick={() => runModal(record)}>
      <Icon name="edit" />
    </Button>
  );

  const afterCancel = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const CustomCreate = () => (
    <Button type="primary" onClick={() => runModal()}>
      Nowa
      {' '}
      <Icon name="plus" />
    </Button>
  );

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="name">
      <Form.Item
        name="name"
      >
        <Input size="large" placeholder="Nazwa" />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="type">
      <Form.Item
        name="type"
      >
        <Select
          placeholder="Grupa"
          allowClear
          size="large"
        >
          <Option value="TENANT_CHANGE">
            Zmiany lokatorskie
          </Option>
          <Option value="FAULT">
            Usterki
          </Option>
          <Option value="PREMISES">
            Lokale
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  return (
    <div>
      <Datagrid
        title="Lista tagów"
        resource="tags"
        dataProvider={tagsService}
        columns={columns}
        customRowActions={[customEdit]}
        ref={datagridRef}
        customCreate={<CustomCreate />}
        customFilters={customFilters}
        hideEdit
        hideShow
      />
      <Popup
        data={modalData}
        isModalVisible={showModal}
        afterSave={afterSave}
        afterCancel={afterCancel}
      />
    </div>
  );
};

export default List;
