/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button, Row, Col, Tooltip, Alert,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import FaultsS from '../../../services/faultsService';
import faultGroupService from '../../../services/faultGroupService';
import userService from '../../../services/userService';
import faultsStatusMap from '../../../helpers/faultsStatusMap';
import { ImagesBox } from './showComponents/ImagesBox';
import { DescriptionBox } from './showComponents/DescriptionBox';
import { InfoBox } from './showComponents/InfoBox';
import DeveloperNoteModal from './showComponents/Modal';
import { AttachmentsBox } from './showComponents/AttachmentsBox';
import { CostsBox } from './showComponents/CostsBox';
import { Icon, Spin } from '../../../components';

const Dot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const Show = () => {
  const { investmentId, faultGroupId, faultId } = useParams();
  const history = useHistory();
  const [fault, setFault] = useState({});
  const [faultGroup, setFaultGroup] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      const { roles } = data;
      setUserRoles(roles);
      const FaultGroupService = new faultGroupService(investmentId);

      FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
        setFaultGroup(faultGroupData);
      });

      const FaultsService = new FaultsS(investmentId, faultGroupId);
      FaultsService.getOne(faultId).then((faultData) => {
        setFault(faultData);
        setLoading(false);
      });
    });
  }, [faultId, investmentId, faultGroupId]);

  const handleBack = () => {
    const { location: { state } } = history;

    switch (state?.redirectFrom) {
      case 'FaultGroupEdit':
        return history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/edit`);
      case 'FaultGroupList':
        return history.push('/faultGroup');
      case 'ClientFaultGroupList':
        return history.push(`/clients/${state.userId}/faultGroup`);
      case 'FaultShow':
        return history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${fault.id}/show`);
      default:
        return history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/show`);
    }
  };

  let shoudRenderDeveloperNotification = false;
  if (!loading && (userRoles.includes('ROLE_DEVELOPER') || userRoles.includes('ROLE_TENANT'))) {
    if (fault.isCorrect === null) {
      shoudRenderDeveloperNotification = true;
    }
  }

  const CustomAlert = () => {
    const [showModal, setShowModal] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const FaultsService = new FaultsS(investmentId, faultGroupId);

    const confirm = () => {
      setIsCorrect(false);
      setShowModal(false);
      FaultsService.getOne(faultId).then((faultData) => {
        setFault(faultData);
      });
    };

    const cancel = () => {
      setIsCorrect(false);
      setShowModal(false);
    };

    const modalView = (val) => {
      setShowModal(true);
      setIsCorrect(val);
    };

    const clientName = userRoles.includes('ROLE_CLIENT_TENANT') ? 'Najemca' : 'Klient';
    return (
      <>
        <Alert
          style={{ width: '100%' }}
          type="info"
          message={(
            <>
              <span className="font-bold">{clientName}</span>
              {' '}
              dodał nową usterkę. Potwierdź jej zasadność.
            </>
        )}
          action={[
            <Button type="primary" className="confirm-fault-button" onClick={() => modalView(true)}>
              Potwierdzam odbiór
            </Button>,
            <Button className="button-secondary" type="text" onClick={() => modalView(false)}>
              Odrzuć
            </Button>,
          ]}
        />
        <DeveloperNoteModal
          data={fault}
          isModalVisible={showModal}
          isCorrect={isCorrect}
          afterCancel={cancel}
          afterSave={confirm}
          investmentId={investmentId}
        />
      </>
    );
  };

  return (
    <div className="show-layout faults-show">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name="arrow-simply-left" />}
          title="Wróć"
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            <Link
              key="2"
              to={{
                pathname: `/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${faultId}/edit`,
                state: { redirectFrom: 'FaultShow' },
              }}
            >
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>,
          ]}
        >
          <div className="premises-show">
            <div className="premises-show-header">
              <Row justify="space-between">
                <Col sm={24}>
                  <h1>
                    {fault.name}
                    <Dot {...fault} />
                  </h1>
                </Col>
              </Row>
            </div>
            {shoudRenderDeveloperNotification && (
              <div className="faults-notification">
                <Col xs={24} className="tags-filter">
                  <CustomAlert />
                </Col>
              </div>
            )}
            <div className="premises-show-slider">
              <ImagesBox />
            </div>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <InfoBox fault={fault} faultGroup={faultGroup} />
                <CostsBox fault={fault} />
              </Col>
              <Col xs={24} sm={12}>
                {fault.developerNote && (
                <DescriptionBox fault={fault} valueKey="developerNote" title="Notatka od dewelopera" />
                )}
                <DescriptionBox fault={fault} />
                <AttachmentsBox />
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
