import React, { useState, useRef, useEffect } from 'react';
import {
  Tooltip, Button, message, Col, Form,
} from 'antd';
import moment from 'moment';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import Spin from '../../components/Spin';

import userService from '../../services/userService';
import receiptsService from '../../services/receiptsService';
import tenantChangesService from '../../services/tenantChangeGroups';
import tranchesService from '../../services/tranchesService';
import investmentsService from '../../services/investmentsService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import recipeTypeMap from '../../helpers/recipeTypeMap';
import reciptStatusMap from '../../helpers/reciptStatusMap';
import Edit from './Edit';

const { Select, SearchBox, DatePicker } = Input;
const { Option } = Select;

const Dot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'PAID') {
    color = 'green';
  }
  if (status === 'PARTIALLY_PAID') {
    color = 'yellow';
  }

  return (
    <Tooltip title={reciptStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const calculateAmountToPay = (source) => source.amount - source.paid;

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: false,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => recipeTypeMap(record.type),
    exportRender: (text, record) => recipeTypeMap(record.type),
  },
  {
    title: 'Typ lokalu',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.premisesType),
    exportRender: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Termnin płatności',
    dataIndex: 'expirationDate',
    visible: true,
    key: 'expirationDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => reciptStatusMap(record.status),
  },
  {
    title: 'Do zapłaty (brutto)',
    dataIndex: 'paid',
    visible: true,
    key: 'paid',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    summary: true,
    render: (text, record) => (<Price value={calculateAmountToPay(record)} />),
    exportRender: (text, record) => calculateAmountToPay(record),
  },
  {
    title: 'Data opłacenia',
    dataIndex: 'paymentDate',
    visible: true,
    key: 'paymentDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Kwota (brutto)',
    dataIndex: 'amount',
    visible: true,
    key: 'amount',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    summary: true,
    render: (text, record) => (<Price value={record.amount} />),
  },
];

const List = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canEditTranches, setCanEditTranches] = useState(false);
  const [recordType, setRecordType] = useState();
  const [modalData, setModalData] = useState({});
  const [receipt, setReceipt] = useState({});
  const datagridRef = useRef();

  useEffect(() => {
    userService.getInfo().then((response) => {
      if (response.roles.includes('ROLE_WORKER')) {
        if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEditTranches(true);
        }
      } else {
        setCanEditTranches(true);
      }
      setLoading(false);
    });
  }, []);

  const afterSave = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const runModal = (record) => {
    setReceipt(record);
    if (record.type === 'TENANT_CHANGE') {
      const TenantChangeService = new tenantChangesService(record.investmentId, record.premisesId);
      TenantChangeService.getOne(record.id).then((response) => {
        setRecordType('TENANT_CHANGE');
        setModalData(response);
        setShowModal(true);
      });
    } else {
      const TranchesService = new tranchesService(record.investmentId, record.premisesId);
      TranchesService.getOne(record.id).then((response) => {
        setRecordType('TRANCHE');
        setModalData(response);
        setShowModal(true);
      });
    }
  };

  const markAsPaid = (record) => {
    if (record.type === 'TENANT_CHANGE') {
      const TenantChangesService = new tenantChangesService(
        record.investmentId, record.premisesId,
      );

      TenantChangesService.getOne(record.id).then((response) => {
        const values = {
          ...response,
          paid: response.amount,
          paymentDate: moment().format('YYYY-MM-DD'),
          paymentStatus: 'PAID',
        };
        TenantChangesService.update(record.id, values).then((resp) => {
          afterSave(resp);
        }).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });
    } else {
      const TranchesService = new tranchesService(record.investmentId, record.premisesId);

      TranchesService.getOne(record.id).then((response) => {
        const values = {
          ...response,
          paid: response.amount,
          paymentDate: moment().format('YYYY-MM-DD'),
          status: 'PAID',
        };
        TranchesService.update(record.id, values).then((resp) => {
          afterSave(resp);
        }).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });
    }
  };

  const markAsPaidAction = (record) => {
    if (record.type === 'TRANCHE' && !canEditTranches) {
      return '';
    }

    if (record.status === 'PAID') {
      return '';
    }

    return (
      <Button className="button-no-border" onClick={() => markAsPaid(record)}>
        <Icon name="dolar" />
      </Button>
    );
  };

  const customEdit = (record) => {
    if (record.type === 'TRANCHE' && !canEditTranches) {
      return '';
    }

    return (
      <Button className="button-no-border" onClick={() => runModal(record)}>
        <Icon name="edit" />
      </Button>
    );
  };

  const afterCancel = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="empty" />,
  );
  customFilters.push(
    <Col xs={24} sm={12} key="type">
      <Form.Item
        name="type"
      >
        <Select
          placeholder="Typ"
          allowClear
          size="large"
        >
          <Option value="TRANCHE">
            Transza
          </Option>
          <Option value="TENANT_CHANGE">
            Zmiana lokatorska
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="premiseType">
      <Form.Item
        name="premisesType"
      >
        <Select
          placeholder="Typ lokalu"
          allowClear
          size="large"
        >
          <Option value="SALE">Mieszkanie</Option>
          <Option value="STORAGE">Komórka lokatorska</Option>
          <Option value="PARKING">Miejsce postojowe</Option>
          <Option value="SERVICE">Lokal usługowy</Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12}>
      <Form.Item
        name="investmentId"
      >
        <SearchBox
          placeholder="Inwestycja"
          size="large"
          resource={new investmentsService()}
          filters={{
            types: 'SALE,RENT',
          }}
          resourceField="name"
        />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="TO_PAY">
            <span className="dot red" />
            Do zapłaty
          </Option>
          <Option value="PARTIALLY_PAID">
            <span className="dot yellow" />
            Cześciowo zapłacone
          </Option>
          <Option value="PAID">
            <span className="dot green" />
            Zapłacone
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="expirationDateFrom">
      <Form.Item
        name="expirationDateFrom"
      >
        <DatePicker size="large" placeholder="Termin płatności od" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="expirationDateTo">
      <Form.Item
        name="expirationDateTo"
      >
        <DatePicker size="large" placeholder="Termin płatności do" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  const customRowClass = (record) => {
    if (!record?.expirationDate) {
      return '';
    }

    if (record.status === 'PAID') {
      return '';
    }

    const expirationDate = moment(record.expirationDate);
    if (expirationDate.isBefore(moment(), 'day')) {
      return 'row-error-red';
    }
    return '';
  };

  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <>
          <Datagrid
            title="Lista wpływów"
            resource="receipts"
            dataProvider={receiptsService}
            columns={columns}
            hideShow
            hideEdit
            customFilters={customFilters}
            customRowActions={[markAsPaidAction, customEdit]}
            ref={datagridRef}
            hideCreate
            customRowClass={customRowClass}
          />
          <Edit
            data={modalData}
            receipt={receipt}
            isModalVisible={showModal}
            recordType={recordType}
            afterSave={afterSave}
            afterCancel={afterCancel}
          />
        </>
      )}
    </div>
  );
};

export default List;
