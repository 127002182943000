import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form, DatePicker, Tooltip,
} from 'antd';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';

import StatsS from '../../../services/statsService';
import Spin from '../../../components/Spin';
import Icon from '../../../components/Icon';

const useQuery = () => new URLSearchParams(useLocation().search);

const TranchesGrowthChart = ({ investmentType }) => {
  const query = useQuery();
  const id = query.get('id') || null;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [chartConfig, setChartConfig] = useState({});

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label(tooltipItems) {
          return `${tooltipItems.value} zł`;
        },
      },
    },
  };

  useEffect(() => {
    const StatsService = new StatsS(id);
    let endpoint = {};
    if (investmentType === 'RENT') {
      endpoint = StatsService.getPayment(id, {
        dateFrom: moment().subtract(1, 'years').startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        investmentType,
      });
    } else {
      endpoint = StatsService.getTranche(id, {
        dateFrom: moment().subtract(1, 'years').startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        investmentType,
      });
    }

    endpoint.then((response) => {
      const tempResponse = Object.keys(response.paid).map((label) => ({
        date: label,
        value: response.paid[label],
      }));
      tempResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

      const earningsChartConfig = {
        labels: tempResponse.map((label) => moment(label.date).format('MMMM')),
        datasets: [
          {
            backgroundColor: 'rgba(21,214,94,0.7)',
            borderWidth: 0,
            hoverBackgroundColor: 'rgba(21,214,94,1)',
            data: tempResponse.map((label) => label.value),
          },
        ],
      };

      setChartConfig(earningsChartConfig);
      setLoading(false);
    });
  }, [id, investmentType]);

  const handleDates = () => {
    const dateFrom = form.getFieldValue('dateFrom');
    const dateTo = form.getFieldValue('dateTo');
    const params = {
      investmentType,
    };
    if (dateFrom) {
      params.dateFrom = dateFrom.startOf('month').format('YYYY-MM-DD');
    }
    if (dateTo) {
      params.dateTo = dateTo.endOf('month').format('YYYY-MM-DD');
    } else if (dateFrom) {
      params.dateTo = moment().endOf('month').format('YYYY-MM-DD');
    }

    const StatsService = new StatsS(id);
    let endpoint = {};
    if (investmentType === 'RENT') {
      endpoint = StatsService.getPayment(id, params);
    } else {
      endpoint = StatsService.getTranche(id, params);
    }
    endpoint.then((response) => {
      const tempResponse = Object.keys(response.paid).map((label) => ({
        date: label,
        value: response.paid[label],
      }));
      tempResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

      const earningsChartConfig = {
        labels: tempResponse.map((label) => moment(label.date).format('MMMM')),
        datasets: [
          {
            backgroundColor: 'rgba(21,214,94,0.7)',
            borderWidth: 0,
            hoverBackgroundColor: 'rgba(21,214,94,1)',
            data: tempResponse.map((label) => label.value),
          },
        ],
      };

      setChartConfig(earningsChartConfig);
    });
  };

  return (
    <div className="stats-elem">
      <div className="title">
        <h2>
          Wpływy
          {' '}
          <Tooltip title="Suma przychodów w danym miesiącu, na podstawie potwierdzonych płatności">
            <Icon name="more-info" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </h2>
      </div>
      <div className="content">
        {loading ? (
          <div className="loading"><Spin /></div>
        ) : (
          <Row>
            <Col xs={24}>
              <div className="form">
                <Form
                  form={form}
                  name="create"
                  className="create-form"
                  autoComplete="off"
                  layout="inline"
                >
                  <Row>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name="dateFrom"
                        label="Od"
                        rules={[{ required: true, message: 'Pole wymagane' }]}
                      >
                        <DatePicker
                          defaultValue={moment().subtract(1, 'years').startOf('month')}
                          size="small"
                          placeholder="Od"
                          picker="month"
                          onChange={() => handleDates()}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name="dateTo"
                        label="Do"
                        rules={[{ required: true, message: 'Pole wymagane' }]}
                      >
                        <DatePicker
                          defaultValue={moment().endOf('month')}
                          size="small"
                          placeholder="Do"
                          picker="month"
                          onChange={() => handleDates()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              <Bar
                data={chartConfig}
                options={options}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TranchesGrowthChart;
