import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';

const LoggedMenu = ({ shouldShowMenu }) => {
  const location = useLocation();
  const { pathname } = location;

  const getSelectedMenu = () => {
    if (pathname.startsWith('/agreements')) {
      return ['12'];
    }

    if (pathname.startsWith('/developers')) {
      return ['2'];
    }

    if (pathname.startsWith('/clientsTenants')) {
      return ['5'];
    }

    if (pathname.startsWith('/clients')) {
      return ['4'];
    }

    if (pathname.startsWith('/investments')) {
      return ['7'];
    }

    if (pathname.startsWith('/tenantChanges')) {
      return ['11'];
    }

    if (pathname.startsWith('/tenants')) {
      return ['3'];
    }

    if (pathname.startsWith('/workers')) {
      return ['6'];
    }

    if (pathname.startsWith('/payments')) {
      return ['8'];
    }

    if (pathname.startsWith('/packagePayments')) {
      return ['14'];
    }

    if (pathname.startsWith('/settlements')) {
      return ['9'];
    }

    if (pathname.startsWith('/faultGroup')) {
      return ['10'];
    }

    if (pathname.startsWith('/premises')) {
      return ['20'];
    }

    if (pathname.startsWith('/packages')) {
      return ['13'];
    }

    if (pathname.startsWith('/receipts')) {
      return ['21'];
    }

    if (pathname.startsWith('/tags')) {
      return ['22'];
    }

    if (pathname.startsWith('/receivables')) {
      return ['30'];
    }

    return ['1'];
  };

  const toggleChat = () => {
    // eslint-disable-next-line no-undef
    $czaterMethods.showChat();
  };

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={getSelectedMenu()}>
      <Menu.Item key="1" icon={<Icon name="dashboard" />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT', 'ROLE_WORKER'], 'INVESTMENT_READ') && (
      <Menu.Item key="7" icon={<Icon name="investment" />}>
        <Link to="/investments">Inwestycje</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_TENANT', 'ROLE_WORKER', 'ROLE_CLIENT'], 'INVESTMENT_READ', 'ROLE_TENANT') && (
      <Menu.Item key="20" icon={<Icon name="apartments" />}>
        <Link to="/premises">Lokale</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_TENANT', 'ROLE_WORKER'], 'CLIENTS_FULL', 'ROLE_TENANT') && (
      <Menu.Item key="5" icon={<Icon name="tenant" />}>
        <Link to="/clientsTenants">Najemcy</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_WORKER'], 'CLIENTS_READ', 'ROLE_DEVELOPER') && (
      <Menu.Item key="4" icon={<Icon name="client" />}>
        <Link to="/clients">Klienci</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT', 'ROLE_WORKER'], 'WORKERS_FULL') && (
      <Menu.Item key="6" icon={<Icon name="worker" />}>
        <Link to="/workers">Pracownicy</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT', 'ROLE_WORKER', 'ROLE_CLIENT_TENANT', 'ROLE_CLIENT'], 'FAULTS_FULL') && (
      <Menu.Item key="10" icon={<Icon name="fault" />}>
        <Link to="/faultGroup">Usterki</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_WORKER', 'ROLE_CLIENT'], 'TENANTS_CHANGES_FULL') && (
      <Menu.Item key="11" icon={<Icon name="flat-changes" />}>
        <Link to="/tenantChangeGroup">Zmiany lokatorskie</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_WORKER_BROKER'], 'PAYMENTS_FULL') && (
      <Menu.Item key="9" icon={<Icon name="payments" />}>
        <Link to="/settlements">Rozliczenia</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_WORKER'], 'RECEIPTS_FULL') && (
      <Menu.Item key="21" icon={<Icon name="dolar" />}>
        <Link to="/receipts">Wpływy</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_TENANT', 'ROLE_WORKER', 'ROLE_CLIENT_TENANT'], 'PAYMENTS_FULL', 'ROLE_TENANT') && (
      <Menu.Item key="8" icon={<Icon name="card" />}>
        <Link to="/payments">Płatności</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_CLIENT']) && (
      <Menu.Item key="30" icon={<Icon name="card" />}>
        <Link to="/receivables">Płatności</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT']) && (
      <Menu.Item key="14" icon={<Icon name="package" />}>
        <Link to="/packagePayments">Płatności za pakiety</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_CLIENT_TENANT', 'ROLE_CLIENT']) && (
      <Menu.Item key="12" icon={<Icon name="file" />}>
        <Link to="/agreements">Umowy</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
      <Menu.Item key="2" icon={<Icon name="developer" />}>
        <Link to="/developers">Deweloperzy</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
      <Menu.Item key="3" icon={<Icon name="rental-managers" />}>
        <Link to="/tenants">Zarządcy najmem</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
      <Menu.Item key="13" icon={<Icon name="flat-changes" />}>
        <Link to="/packages">Pakiety</Link>
      </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT', 'ROLE_WORKER'], 'TAGS_FULL') && (
      <Menu.Item key="22" icon={<Icon name="flat-changes" />}>
        <Link to="/tags">Tagi</Link>
      </Menu.Item>
      )}
      <Menu.Item key="98" className="help" icon={<Icon name="samouczek" />}>
        <a rel="noreferrer" target="_blank" href="https://manage.com.pl/samouczek.pdf">Samouczek</a>
      </Menu.Item>
      <Menu.Item key="99" className="help" icon={<Icon name="help" />} style={{ marginTop: 0 }}>
        <a href="mailto:support@manage.com.pl">Zgłoś problem</a>
      </Menu.Item>
      <Menu.Item key="100" className="help" icon={<Icon name="email" />} onClick={() => toggleChat()} style={{ marginTop: 0 }}>
        Czat
      </Menu.Item>
      {shouldShowMenu(['ROLE_CLIENT_TENANT', 'ROLE_CLIENT']) && (
      <Menu.Item key="101" className="help" icon={<Icon name="rodo" />} style={{ marginTop: 0 }}>
        <Link to="/rodo">Rodo</Link>
      </Menu.Item>
      )}
    </Menu>
  );
};

export default LoggedMenu;
