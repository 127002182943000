import React, { useState, useEffect, useRef } from 'react';
import {
  PageHeader, Dropdown, Button, Menu, Col, Form, Tooltip,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import investmentsService from '../../../services/investmentsService';
import premisesService from '../../../services/premisesService';
import userService from '../../../services/userService';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import Datagrid from '../../../components/Datagrid';
import Price from '../../../components/Price';
import premiseStatusMap from '../../../helpers/premiseStatusMap';
import premiseTypeMap from '../../../helpers/premiseTypeMap';
import Statisticts from './Statistics';
import Spin from '../../../components/Spin';

const { Select } = Input;
const { Option } = Select;

const Dot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'FREE') {
    color = 'green';
  }
  if (status === 'PARTIAL_RENT') {
    color = 'halfred';
  }
  if (status === 'RESERVATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={premiseStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const SurfaceRenderer = ({ totalSurface }) => (
  <span>
    {totalSurface}
    {' '}
    m
    <sup>2</sup>
  </span>
);

const RoomsRenderer = ({ roomsCount, investmentType }) => (
  <span>
    {investmentType === 'STORAGE' || investmentType === 'PARKING' ? (
      '-'
    ) : (
      roomsCount
    )}
  </span>
);

const columns = [
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.type),
    exportRender: (text, record) => premiseTypeMap(record.type),
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<SurfaceRenderer totalSurface={record.totalSurface} />),
  },
  {
    title: 'Liczba pokoi',
    dataIndex: 'roomsCount',
    visible: false,
    key: 'roomsCount',
    sorter: false,
    render: (text, record) => (
      <RoomsRenderer
        roomsCount={record.roomsCount}
        investmentType={record.type}
      />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => premiseStatusMap(record.status),
  },
];

const rentColumns = [
  ...columns,
  {
    title: 'Koszt miesięczny (brutto)',
    dataIndex: 'rentPrice',
    visible: true,
    key: 'rentPrice',
    sorter: false,
    render: (text, record) => (<Price value={record.rentPrice} />),
  },
];

const menu = (investment) => (
  <Menu>
    <Menu.Item key="1" icon={<Icon name="apartments" style={{ marginRight: '5px' }} />}>
      <Link to={`premises/create?type=${investment.type}`} className="premises-custom-create-link">
        Mieszkanie
      </Link>
    </Menu.Item>
    <Menu.Item key="2" icon={<Icon name="apartments-services" style={{ marginRight: '5px' }} />}>
      <Link to="premises/create?type=SERVICE" className="premises-custom-create-link">
        Lokal usługowy
      </Link>
    </Menu.Item>
    <Menu.Item key="3" icon={<Icon name="parking" style={{ marginRight: '5px' }} />}>
      <Link to="premises/create?type=PARKING" className="premises-custom-create-link">
        Miejsce postojowe
      </Link>
    </Menu.Item>
    <Menu.Item key="4" icon={<Icon name="storage-place" style={{ marginRight: '5px' }} />}>
      <Link to="premises/create?type=STORAGE" className="premises-custom-create-link">
        Komórka lokatorska
      </Link>
    </Menu.Item>
  </Menu>
);

const List = () => {
  const { id } = useParams();
  const [investment, setInvestment] = useState({});
  const [premisesStats, setPremisesStats] = useState({});
  const [premisesCount, setPremisesCount] = useState({});
  const [saleStats, setSaleStats] = useState({});
  const [datagridFilters, setDatagridFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [canSeePrices, setCanSeePrices] = useState(true);
  const [canSeeStats, setCanSeeStats] = useState(false);
  const PremisesService = new premisesService(id);

  const datagridRef = useRef();

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getDetails(id).then((data) => {
      setInvestment(data.investment);
      setPremisesCount(data.premisesCount);
    });

    InvestmentsService.getPremises(id).then((data) => {
      setPremisesStats(data);
    });

    userService.getInfo().then((userData) => {
      if (userData.roles.includes('ROLE_WORKER') && userData.ownerRoles.includes('ROLE_DEVELOPER')) {
        if (userData.workerPermissions.includes('STATS_FULL')) {
          setCanSeeStats(true);
        }
        if (userData.workerPermissions.includes('INVESTMENT_FULL') && userData.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanEdit(true);
          setCanSeePrices(true);
        } else if (userData.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanSeePrices(false);
          setCanEdit(false);
        } else if (userData.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanSeePrices(true);
          setCanEdit(false);
        } else {
          setCanSeePrices(false);
          setCanEdit(false);
        }
      } else if (userData.roles.includes('ROLE_WORKER') && userData.ownerRoles.includes('ROLE_TENANT')) {
        if (userData.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
        setCanSeePrices(true);
        setCanSeeStats(true);
      } else {
        setCanEdit(true);
        setCanSeePrices(true);
        setCanSeeStats(true);
      }
      if (canSeeStats) {
        InvestmentsService.getPremisesSale(id).then((data) => {
          setSaleStats(data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [id, canSeeStats]);

  const saleColumns = [
    ...columns,
  ];

  if (canSeePrices) {
    saleColumns.push({
      title: 'Cena minimalna (brutto)',
      dataIndex: 'priceMinimal',
      visible: true,
      key: 'priceMinimal',
      sorter: false,
      render: (text, record) => (<Price value={record.priceMinimal} />),
    },
    {
      title: 'Cena sprzedaży (brutto)',
      dataIndex: 'priceSell',
      visible: true,
      key: 'priceSell',
      sorter: false,
      render: (text, record) => (<Price value={record.priceSell} />),
    },
    {
      title: 'Cena sugerowana',
      dataIndex: 'priceSuggest',
      visible: false,
      key: 'priceSuggest',
      sorter: false,
      render: (text, record) => (<Price value={record.priceSell} />),
    });
  }

  const CustomCreate = (props) => {
    if (!canEdit) {
      return '';
    }

    const { investment: invest } = props;
    return (
      <Dropdown overlay={() => menu(invest)} trigger="click" placement="bottomCenter" className="test">
        <Button type="primary">
          Nowy
          {' '}
          <Icon name="plus" />
        </Button>
      </Dropdown>
    );
  };

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="type">
      <Form.Item
        name="type"
      >
        <Select
          placeholder="Typ"
          allowClear
          size="large"
        >
          {investment.type === 'SALE' && (<Option value="SALE">Mieszkanie</Option>)}
          {investment.type === 'RENT' && (<Option value="RENT">Mieszkanie</Option>)}
          <Option value="STORAGE">Komórka lokatorska</Option>
          <Option value="PARKING">Miejsce postojowe</Option>
          <Option value="SERVICE">Lokal usługowy</Option>
        </Select>
      </Form.Item>
    </Col>,
  );
  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="FREE">
            <span className="dot green" />
            Wolne
          </Option>
          {investment.type === 'SALE' && (
          <Option value="RESERVATION">
            <span className="dot yellow" />
            Zarezerwowane
          </Option>
          )}
          {investment.type === 'SALE' && (
          <Option value="SOLD">
            <span className="dot red" />
            Sprzedane
          </Option>
          )}
          {investment.type === 'RENT' && (
          <Option value="PARTIAL_RENT">
            <span className="dot halfred" />
            Częściowo wynajęte
          </Option>
          )}
          {investment.type === 'RENT' && (
          <Option value="RENT">
            <span className="dot red" />
            Wynajęte
          </Option>
          )}
        </Select>
      </Form.Item>
    </Col>,
  );

  const setStatsFilters = (e) => {
    datagridRef.current.handleFilterChange(e, true);
  };

  const afterFilterChange = () => {
    setDatagridFilters(datagridRef.current.state.filters);
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => window.history.back()}
          title={investment.name}
        >
          <div className="premises-list">
            {(Object.keys(premisesCount).length > 0) && (
              <Statisticts
                type={investment.type}
                premisesStats={premisesStats}
                saleStats={saleStats}
                setFilters={setStatsFilters}
                datagridFilters={datagridFilters}
                canEdit={canEdit}
                canSeePrices={canSeePrices}
                canSeeStats={canSeeStats}
              />
            )}

            <Datagrid
              resource={`investments/${id}/premises`}
              dataProvider={PremisesService}
              columns={investment.type === 'SALE' ? saleColumns : rentColumns}
              customCreate={<CustomCreate investment={investment} />}
              customFilters={customFilters}
              hideEdit={!canEdit}
              canEdit={canEdit}
              sort={{
                sortField: 'name',
                sortOrder: 'ascend',
              }}
              ref={datagridRef}
              afterFilterChange={afterFilterChange}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
