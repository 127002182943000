import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Form, Button, PageHeader, Row, Col, Divider, Upload, message,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusOutlined } from '@ant-design/icons';
import investmentsService from '../../services/investmentsService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const { InputNumber, DatePicker, Select } = Input;
const { Option } = Select;

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [logo, setLogo] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [investment, setInvestment] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      const tempData = data;
      if (data.createYear) {
        tempData.createYear = moment(data.createYear, 'YYYY');
      }
      setInvestment(tempData);
      form.setFieldsValue(tempData);
    });
  }, [form, id]);

  const onFinish = (values) => {
    const data = {
      ...investment,
      ...values,
      createYear: values.createYear.format('YYYY'),
    };
    delete data.id;
    delete data.userId;
    delete data.logoFile;
    delete data.thumbnailFile;
    const formData = new FormData();
    formData.append('investment', JSON.stringify(data));
    if (logo.length) {
      formData.append('logo', logo[0].originFileObj);
    }

    if (thumb.length) {
      formData.append('thumbnail', thumb[0].originFileObj);
    }

    const InvestmentsService = new investmentsService();
    InvestmentsService.update(id, formData).then((response) => {
      if (response.id) {
        history.push(`/investments/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleLogoChange = (info) => {
    setLogo(info.fileList);
  };
  const handleThumbChange = (info) => {
    setThumb(info.fileList);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Dodaj plik</div>
    </div>
  );

  const handleOk = () => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.delete(id).then(() => history.push('/investments')).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleCancel = () => {
    history.push('/investments');
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{investment.name}</span>
      </span>
    );
  };

  const LogoRenderer = () => {
    if (investment.logoFile && logo.length === 0) {
      return <img src={`${baseUrl}${investment.logoFile}`} alt={investment.name} className="ant-upload-list-item-thumbnail" />;
    }
    if (logo.length === 0) {
      return uploadButton;
    }

    return '';
  };

  const ThumbRenderer = () => {
    if (investment.thumbnailFile && thumb.length === 0) {
      return <img src={`${baseUrl}${investment.thumbnailFile}`} alt={investment.name} className="ant-upload-list-item-thumbnail" />;
    }
    if (thumb.length === 0) {
      return uploadButton;
    }

    return '';
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/investments')}
        title={<HeaderTitle />}
        extra={[
          <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
            <Icon name="delete" style={{ marginRight: '5px' }} />
            {' '}
            Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <div className="create-form-wrapper">
              <Row>
                <Col xs={24} sm={{ span: 20, offset: 2 }}>
                  <div className="form-section">
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <h2>Logo inwestycji</h2>
                        <Upload
                          listType="picture-card"
                          fileList={logo}
                          onChange={handleLogoChange}
                          beforeUpload={beforeUpload}
                          maxCount={1}
                        >
                          <LogoRenderer />
                        </Upload>
                        <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                      </Col>
                      <Col xs={24} sm={12}>
                        <h2>Miniaturka</h2>
                        <Upload
                          listType="picture-card"
                          fileList={thumb}
                          onChange={handleThumbChange}
                          beforeUpload={beforeUpload}
                          maxCount={1}
                        >
                          <ThumbRenderer />
                        </Upload>
                        <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Dane podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="name"
                        >
                          <Input size="large" placeholder="Nazwa inwestycji" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="createYear"
                        >
                          <DatePicker size="large" placeholder="Rok powstania" style={{ width: '100%' }} picker="year" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Adres inwestycji</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'street']}
                        >
                          <Input size="large" placeholder="Ulica" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'number']}
                        >
                          <Input size="large" placeholder="Numer" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'city']}
                        >
                          <Input size="large" placeholder="Miejscowość" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'zip']}
                        >
                          <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'country']}
                        >
                          <CountrySelect />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Administrator</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'firstName']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Imię" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'lastName']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Nazwisko" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'phone']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <InputNumber size="large" placeholder="Telefon" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'email']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Adres e-mail" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Źródło ciepła</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="heatSource"
                          rules={[{ required: true, message: 'Proszę wybrać źródło ciepła' }]}
                        >
                          <Select
                            placeholder="Źródło ciepła"
                            size="large"
                          >
                            <Option value="GAS">Gaz</Option>
                            <Option value="SYSTEM">Ciepło systemowe</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Edit;
