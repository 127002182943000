import React from 'react';
import {
  Table, Button, Row, Col, Popover, Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FilterColumns from './datagrid/FilterColumns';
import FilterModal from './datagrid/FilterModal';
import RowActions from './datagrid/RowActions';
import MassActions from './datagrid/MassActions';
import Icon from './Icon';
import Price from './Price';

const { Text } = Typography;

class Datagrid extends React.Component {
  constructor(props) {
    super(props);

    const { columns } = this.props;

    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
      selectedRowKeys: [],
      loading: false,
      columns,
      requestParams: {},
      filters: {},
      showColumnsPopover: false,
    };

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { pagination } = this.state;
    const { resource, afterFilterChange = () => '' } = this.props;
    let requestBody = { pagination };
    if (sessionStorage.getItem(resource)) {
      const sessionRestore = JSON.parse(sessionStorage.getItem(resource));
      requestBody = sessionRestore.requestParams;

      if (sessionRestore.filters && Object.keys(sessionRestore.filters).length !== 0) {
        this.setState({ filters: sessionRestore.filters }, afterFilterChange);
      }
    }

    this.fetch({ ...requestBody });
  }

  componentDidUpdate(prevProps) {
    const { filters, dynamicFilters, afterFilterChange = () => '' } = this.props;
    if (dynamicFilters && prevProps.filters.type !== filters.type) {
      this.fetch({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        filters,
      }).then(() => afterFilterChange());
    }
  }

  componentWillUnmount() {
    const { resource } = this.props;
    const { requestParams, filters } = this.state;
    const sessionObject = {
      requestParams,
      filters,
    };
    if (resource === 'receipts') {
      delete sessionObject.requestParams.sortField;
      delete sessionObject.requestParams.sortOrder;
    }
    if (resource === 'faultGroup' || resource === 'paymentsAGREEMENT' || resource === 'tenantChangeGroup') {
      delete sessionObject?.requestParams?.status;
    }

    sessionStorage.setItem(resource, JSON.stringify(sessionObject));
  }

  updateColumnsVisibility = (values) => {
    const { columns } = this.state;

    Object.keys(values).forEach((key) => {
      const foundIndex = columns.findIndex((x) => x.key === key);
      columns[foundIndex].visible = values[key];
    });
    this.setState({ columns });
  }

  handleFilterChange = (filters, keepSort = false) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] && moment.isMoment(filters[key])) {
        // eslint-disable-next-line no-param-reassign
        filters[key] = filters[key].format('YYYY-MM-DD');
      }
    });
    this.setState({ filters });
    const { pagination } = this.state;
    const { afterFilterChange = () => '', sort } = this.props;
    let data = {
      sortField: null,
      sortOrder: null,
      pagination: {
        current: 1,
        pageSize: pagination.pageSize,
      },
      ...filters,
    };

    if (keepSort) {
      data = {
        ...data,
        ...sort,
      };
    }

    this.fetch(data).then(() => afterFilterChange());
  }

  handleTableChange = (pagination, nonUsed, sorter) => {
    const { filters } = this.state;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  fetch = (params = {}) => {
    const {
      filters, dataProvider, sort, directResponse = false,
    } = this.props;
    const requestParams = {
      ...filters,
      ...sort,
      ...params,
    };
    this.setState({ loading: true });
    return dataProvider.getList(requestParams).then((data) => {
      this.setState({
        loading: false,
        data: directResponse ? data : data.content,
        pagination: {
          ...params.pagination,
          total: data.totalElements,
        },
        requestParams,
      });
    });
  };

  refresh = () => {
    const { filters, pagination } = this.state;
    this.fetch({
      pagination,
      ...filters,
    });
  }

  getExpandableObject = () => {
    const { expandedRowRenderer, columns } = this.props;

    if (!expandedRowRenderer) {
      return {};
    }

    return {
      indentSize: 0,
      expandIconColumnIndex: columns.length + 1,
      expandedRowRender: expandedRowRenderer,
      expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
        <Button className="button-no-border" onClick={(e) => onExpand(record, e)}>
          <Icon name="arrow-simply-down rotate-180" />
        </Button>
      ) : (
        <Button className="button-no-border" onClick={(e) => onExpand(record, e)}>
          <Icon name="arrow-simply-down" />
        </Button>
      )),
      expandedRowClassName: () => 'expanded-datagrid-row',
    };
  };

  handleVisibleChange = (visible) => {
    this.setState({ showColumnsPopover: visible });
  };

  hideColumnsPopover = () => {
    this.setState({
      showColumnsPopover: false,
    });
  };

  render() {
    const {
      data, pagination, loading, selectedRowKeys, columns, renderCell, filters, showColumnsPopover,
    } = this.state;
    const {
      dataProvider,
      resource,
      customCreate,
      customBaseUrlKey,
      hideRowActions,
      disableSelection,
      hideEdit,
      hideShow,
      customFilters = [],
      title,
      customShowIcon,
      showAlert,
      customRowActions,
      hideCreate = false,
      canEdit = true,
      customRowClass = () => '',
      hideAllActions = false,
      hideColumns = false,
      hideFilters = false,
      calculateBaseUrl = false,
      disablePagination = false,
      showHeader = true,
      hideDelete = false,
      redirectState = {},
    } = this.props;

    let rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    if (disableSelection) {
      rowSelection = false;
    }

    const hasSelected = selectedRowKeys.length > 0;

    const filteredColumns = columns.filter((column) => column.visible);

    let columnsWithActions = [...filteredColumns];

    if (!hideRowActions || (hideEdit && hideShow)) {
      columnsWithActions = [...filteredColumns, {
        key: 'action',
        align: 'right',
        width: 100,
        render: (text, record) => (
          <RowActions
            record={record}
            resource={resource}
            customBaseUrlKey={customBaseUrlKey}
            hideEdit={hideEdit}
            hideShow={hideShow}
            showIcon={customShowIcon}
            showAlert={showAlert}
            customRowActions={customRowActions}
            calculateBaseUrl={calculateBaseUrl}
            redirectState={redirectState}
          />
        ),
      }];
    }

    columnsWithActions = columnsWithActions.map((column) => ({
      ...column,
      className: `datagrid-column-${column.key}`,
    }));

    const Summary = ({ localData, localColumnsWithActions, localDisableSelection }) => {
      if (localData === null || localData === undefined || localData.length === 0) {
        return '';
      }

      if (localColumnsWithActions.filter((column) => column.summary === true).length > 1) {
        const summaryField = localColumnsWithActions.find((column) => column.summary === true);
        const summaryKey = localColumnsWithActions.findIndex((column) => column.summary === true);
        const total = data.reduce((a, b) => a + (b[summaryField.dataIndex] || 0), 0);
        const emptyFields = [];
        let index;
        for (index = 1; index < summaryKey; index += 1) {
          emptyFields.push(<Table.Summary.Cell />);
        }
        const emptyFields2 = [];
        const temp = localColumnsWithActions.filter((item, key) => key !== summaryKey);
        const summaryField2 = temp.find((column) => column.summary === true);
        const summaryKey2 = temp.findIndex((column) => column.summary === true);

        for (index; index < summaryKey2; index += 1) {
          emptyFields2.push(<Table.Summary.Cell />);
        }

        const total2 = data.reduce((a, b) => a + (b[summaryField2.dataIndex] || 0), 0);

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell />
              <Table.Summary.Cell>
                <Text style={{ color: '#C0D5E4' }}>Suma</Text>
              </Table.Summary.Cell>
              {emptyFields}
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}><Price value={total2 - total} showEmpty /></Text>
              </Table.Summary.Cell>
              {emptyFields2}
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}><Price value={total2} showEmpty /></Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }

      const summaryField = localColumnsWithActions.find((column) => column.summary === true);
      if (!summaryField) {
        return '';
      }
      const summaryKey = localColumnsWithActions.findIndex((column) => column.summary === true);
      const emptyFields = [];
      for (let index = 0; index < summaryKey; index += 1) {
        emptyFields.push(<Table.Summary.Cell />);
      }

      if (localDisableSelection === true) {
        emptyFields.shift();
      }
      const total = data.reduce((a, b) => a + (b[summaryField.dataIndex] || 0), 0);
      return (
        <>
          <Table.Summary.Row>
            {emptyFields}
            <Table.Summary.Cell>
              <Text style={{ color: '#C0D5E4' }}>Suma</Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              <Text style={{ fontWeight: 'bold' }}><Price value={total} showEmpty /></Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      );
    };

    return (
      <div className="datagrid-wrapper">
        {title && (<h1>{title}</h1>)}
        {!hideAllActions && (
          <Row justify="space-between">
            <Col xs={24} sm={8}>
              {hasSelected && (
              <MassActions
                selectedRowKeys={selectedRowKeys}
                dataProvider={dataProvider}
                resource={resource}
                data={data}
                columns={columns}
                renderCell={renderCell}
                tableRef={this.tableRef}
                title={title}
                canEdit={canEdit}
                hideDelete={hideDelete}
              />
              )}
            </Col>
            <Col xs={24} sm={16} className="datagrid-actions-wrapper" style={{ textAlign: 'right' }}>
              {Object.keys(filters).length !== 0 && (
              <Button type="danger" onClick={() => this.handleFilterChange({}, true)}>
                <Icon name="refresh" style={{ paddingLeft: 0 }} />
              </Button>
              )}

              {!hideColumns && (
                <Popover
                  overlayClassName="datagrid-columns-popover-wrapper"
                  placement="bottomLeft"
                  title="Pokaż kolumny"
                  content={(
                    <FilterColumns
                      columns={columns}
                      updateColumnsVisibility={this.updateColumnsVisibility}
                      hidePopover={this.hideColumnsPopover}
                    />
                  )}
                  trigger="click"
                  visible={showColumnsPopover}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <Button className="button-secondary">
                    Kolumny
                    {' '}
                    <Icon name="columns" />
                  </Button>
                </Popover>
              )}
              {!hideFilters && (
                <FilterModal
                  columns={columns}
                  handleFilterChange={this.handleFilterChange}
                  customFilters={customFilters}
                />
              )}
              {customCreate ? (
                React.cloneElement(customCreate, { ...this.props, ...this.state })
              ) : (
                !hideCreate && (
                <Link to={`/${resource}/create`}>
                  <Button type="primary">
                    Nowy
                    {' '}
                    <Icon name="plus" />
                  </Button>
                </Link>
                )
              )}
            </Col>
          </Row>
        )}
        <div ref={this.tableRef}>
          <Table
            columns={columnsWithActions}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            dataSource={data}
            pagination={disablePagination ? false : pagination}
            loading={loading}
            summary={() => (
              <Summary
                localData={data}
                localColumnsWithActions={columnsWithActions}
                localDisableSelection={disableSelection}
              />
            )}
            expandable={this.getExpandableObject()}
            rowClassName={(record, index) => customRowClass(record, index)}
            onChange={this.handleTableChange}
            showHeader={showHeader}
          />
        </div>
      </div>
    );
  }
}

export default Datagrid;
