import React, { useState, useEffect } from 'react';
import userService from '../../services/userService';
import DeveloperDashboard from './components/DeveloperDashboard';
import TenantDashboard from './components/TenantDashboard';
import DeveloperTenantDashboard from './components/DeveloperTenantDashboard';
import WorkerDashboard from './components/WorkerDashboard';
import ClientTenantDashboard from './components/ClientTenantDashboard';
import ClientDashboard from './components/ClientDashboard';
import WorkerBrokerDashboard from './components/WorkerBrokerDashboard';
import Spin from '../../components/Spin';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, []);

  const { roles, workerPermissions, ownerRoles } = userData;
  let DashboardView = '';
  if (roles) {
    if (roles.includes('ROLE_DEVELOPER') && roles.includes('ROLE_TENANT')) {
      DashboardView = <DeveloperTenantDashboard />;
    } else if (roles.includes('ROLE_WORKER') && roles.includes('ROLE_WORKER_BROKER')) {
      DashboardView = (
        <WorkerBrokerDashboard
          workerPermissions={workerPermissions}
          ownerRoles={ownerRoles}
        />
      );
    } else if (roles.includes('ROLE_DEVELOPER')) {
      DashboardView = <DeveloperDashboard />;
    } else if (roles.includes('ROLE_TENANT')) {
      DashboardView = <TenantDashboard />;
    } else if (roles.includes('ROLE_WORKER')) {
      DashboardView = (
        <WorkerDashboard
          workerPermissions={workerPermissions}
          ownerRoles={ownerRoles}
        />
      );
    } else if (roles.includes('ROLE_CLIENT_TENANT')) {
      DashboardView = <ClientTenantDashboard />;
    } else if (roles.includes('ROLE_CLIENT')) {
      DashboardView = <ClientDashboard />;
    }
  }

  return (
    <div style={{ padding: '16px 24px' }}>
      <h1 style={{ fontSize: '24px' }}>Dashboard</h1>

      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <div className="dashboard">
          {DashboardView}
          {/* <DeveloperTenantDashboard />
          <WorkerBrokerDashboard />
          <DeveloperDashboard />
          <TenantDashboard />
          <WorkerDashboard />
          <ClientTenantDashboard /> */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
