import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../helpers/PrivateRoute';
import Dashboard from '../routes/dashboard/Dashboard';

// developers
import DevelopersList from '../routes/developers/List';
import CreateDeveloper from '../routes/developers/Create';
import EditDeveloper from '../routes/developers/Edit';
import ShowDeveloper from '../routes/developers/Show';

// tenants
import TenantsList from '../routes/tenants/List';
import CreateTenant from '../routes/tenants/Create';
import EditTenant from '../routes/tenants/Edit';
import ShowTenant from '../routes/tenants/Show';

// clients
import ClientsList from '../routes/clients/List';
import CreateClient from '../routes/clients/Create';
import EditClient from '../routes/clients/Edit';
import ShowClient from '../routes/clients/Show';
import ClientsPremisesList from '../routes/clients/Premises';
import ClientsFaultsList from '../routes/clients/Faults';
import ClientsTenantChangesList from '../routes/clients/TenantChanges';

// clientsTenants
import ClientsTenantsList from '../routes/clientsTenants/List';
import CreateTenantsClient from '../routes/clientsTenants/Create';
import EditTenantsClient from '../routes/clientsTenants/Edit';
import ShowTenantsClient from '../routes/clientsTenants/Show';
import ClientsTenantsPremisesList from '../routes/clientsTenants/Premises';
import ClientsTenantsFaultsList from '../routes/clientsTenants/Faults';
import ClientsTenantsPaymentsList from '../routes/clientsTenants/Payments';

// workers
import WorkersList from '../routes/workers/List';
import CreateWorker from '../routes/workers/Create';
import EditWorker from '../routes/workers/Edit';
import ShowWorker from '../routes/workers/Show';
import WorkerSettlements from '../routes/workers/Settlements';
import WorkersFaultsList from '../routes/workers/Faults';
import WorkerSettlementsHistory from '../routes/workers/SettlementsHistory';
import WorkerSettlementGroups from '../routes/workers/SettlementsGroups';

// investments
import InvestmentsList from '../routes/investments/List';
import InvestmentsCreate from '../routes/investments/Create';
import InvestmentsEdit from '../routes/investments/Edit';
import InvestmentsShow from '../routes/investments/Show';
import InvestmentsStats from '../routes/investments/Stats';
import InvestmentsPayments from '../routes/investments/Payments';
import InvestmentsSettlements from '../routes/investments/Settlements';

// premises
import PremisesList from '../routes/investments/premises/List';
import PremisesCreate from '../routes/investments/premises/Create';
import PremisesEdit from '../routes/investments/premises/Edit';
import PremisesShow from '../routes/investments/premises/Show';

// tenant changes
import TenantChangesCreate from '../routes/tenantChangeGroups/tenantChange/Create';
import TenantChangesEdit from '../routes/tenantChangeGroups/tenantChange/Edit';
import TenantChangesShow from '../routes/tenantChangeGroups/tenantChange/Show';

// tenant changes groups
import TenantChangesGroupList from '../routes/tenantChangeGroups/List';
import TenantChangesGroupCreate from '../routes/tenantChangeGroups/Create';
import TenantChangesGroupEdit from '../routes/tenantChangeGroups/Edit';
import TenantChangesGroupShow from '../routes/tenantChangeGroups/Show';
import TenantChangesGroupHistory from '../routes/tenantChangeGroups/History';

// fault groups
import FaultsGroupList from '../routes/faultGroup/List';
import FaultsGroupCreate from '../routes/faultGroup/Create';
import FaultsGroupEdit from '../routes/faultGroup/Edit';
import FaultsGroupShow from '../routes/faultGroup/Show';
import FaultsGroupHistory from '../routes/faultGroup/History';

// faults
import FaultCreate from '../routes/faultGroup/fault/Create';
import FaultShow from '../routes/faultGroup/fault/Show';
import FaultEdit from '../routes/faultGroup/fault/Edit';

// payments
import PaymentsList from '../routes/payments/List';
import PaymentsCreate from '../routes/payments/Create';
import PaymentsEdit from '../routes/payments/Edit';
import PaymentsShow from '../routes/payments/Show';

// settlements
import SettlementsList from '../routes/settlementsGroup/List';
import SettlementsShow from '../routes/settlementsGroup/Show';

// agreements
import AgreementsList from '../routes/agreemenets/List';

// my account
import MyAccount from '../routes/myAccount/Show';
import MyAccountEdit from '../routes/myAccount/Edit';
import ChangeView from '../routes/myAccount/ChangeView';

// packages
import PackagesList from '../routes/packages/List';
import PackageCreate from '../routes/packages/Create';
import PackageEdit from '../routes/packages/Edit';
import PackageShow from '../routes/packages/Show';
import ChangePackage from '../routes/myAccount/ChangePackage';

// premises all
import PremisesAllList from '../routes/premisesAll/List';

// receipts
import ReceiptsList from '../routes/receipts/List';

// tags
import TagsList from '../routes/tags/List';

// receivables
import ReceivablesList from '../routes/receivables/List';

// rodo
import RodoPage from '../routes/rodo/Rodo';

const Routes = () => (
  <Switch>
    <PrivateRoute path="/dashboard">
      <Dashboard />
    </PrivateRoute>
    <PrivateRoute path="/developers/create">
      <CreateDeveloper />
    </PrivateRoute>
    <PrivateRoute path="/developers/:id/edit">
      <EditDeveloper />
    </PrivateRoute>
    <PrivateRoute path="/developers/:id/show">
      <ShowDeveloper />
    </PrivateRoute>
    <PrivateRoute path="/developers">
      <DevelopersList />
    </PrivateRoute>
    <PrivateRoute path="/tenants/create">
      <CreateTenant />
    </PrivateRoute>
    <PrivateRoute path="/tenants/:id/edit">
      <EditTenant />
    </PrivateRoute>
    <PrivateRoute path="/tenants/:id/show">
      <ShowTenant />
    </PrivateRoute>
    <PrivateRoute path="/tenants">
      <TenantsList />
    </PrivateRoute>
    <PrivateRoute path="/clients/create">
      <CreateClient />
    </PrivateRoute>
    <PrivateRoute path="/clients/:id/edit">
      <EditClient />
    </PrivateRoute>
    <PrivateRoute path="/clients/:id/show">
      <ShowClient />
    </PrivateRoute>
    <PrivateRoute path="/clients/:id/premises">
      <ClientsPremisesList />
    </PrivateRoute>
    <PrivateRoute path="/clients/:id/faultGroup">
      <ClientsFaultsList />
    </PrivateRoute>
    <PrivateRoute path="/clients/:id/tenantChangeGroups">
      <ClientsTenantChangesList />
    </PrivateRoute>
    <PrivateRoute path="/clients">
      <ClientsList />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/create">
      <CreateTenantsClient />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/:id/edit">
      <EditTenantsClient />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/:id/show">
      <ShowTenantsClient />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/:id/premises">
      <ClientsTenantsPremisesList />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/:id/faultGroup">
      <ClientsTenantsFaultsList />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants/:id/payments">
      <ClientsTenantsPaymentsList />
    </PrivateRoute>
    <PrivateRoute path="/clientsTenants">
      <ClientsTenantsList />
    </PrivateRoute>
    <PrivateRoute path="/workers/create">
      <CreateWorker />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/edit">
      <EditWorker />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/show">
      <ShowWorker />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/settlementGroups">
      <WorkerSettlementGroups />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/settlements/history">
      <WorkerSettlementsHistory />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/settlements">
      <WorkerSettlements />
    </PrivateRoute>
    <PrivateRoute path="/workers/:id/faults">
      <WorkersFaultsList />
    </PrivateRoute>
    <PrivateRoute path="/workers">
      <WorkersList />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/premises/:premisesId/show">
      <PremisesShow />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/premises/:premisesId/edit">
      <PremisesEdit />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/premises/create">
      <PremisesCreate />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/premises">
      <PremisesList />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/show">
      <InvestmentsShow />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/payments">
      <InvestmentsPayments />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/settlements">
      <InvestmentsSettlements />
    </PrivateRoute>
    <PrivateRoute path="/investments/:id/edit">
      <InvestmentsEdit />
    </PrivateRoute>
    <PrivateRoute path="/investments/stats">
      <InvestmentsStats />
    </PrivateRoute>
    <PrivateRoute path="/investments/create">
      <InvestmentsCreate />
    </PrivateRoute>
    <PrivateRoute path="/investments">
      <InvestmentsList />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/:id/show">
      <TenantChangesShow />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/:id/edit">
      <TenantChangesEdit />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/create">
      <TenantChangesCreate />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/history">
      <TenantChangesGroupHistory />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/show">
      <TenantChangesGroupShow />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/edit">
      <TenantChangesGroupEdit />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup/create">
      <TenantChangesGroupCreate />
    </PrivateRoute>
    <PrivateRoute path="/tenantChangeGroup">
      <TenantChangesGroupList />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/edit">
      <FaultEdit />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/show">
      <FaultShow />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/create">
      <FaultCreate />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/history">
      <FaultsGroupHistory />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/show">
      <FaultsGroupShow />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/:investmentId/faultGroup/:faultGroupId/edit">
      <FaultsGroupEdit />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup/create">
      <FaultsGroupCreate />
    </PrivateRoute>
    <PrivateRoute path="/faultGroup">
      <FaultsGroupList />
    </PrivateRoute>
    <PrivateRoute path="/payments/:investmentId/premises/:premisesId/agreement/:agreementId/payment/:id/edit">
      <PaymentsEdit />
    </PrivateRoute>
    <PrivateRoute path="/payments/:investmentId/premises/:premisesId/agreement/:agreementId/payment/:id/show">
      <PaymentsShow />
    </PrivateRoute>
    <PrivateRoute path="/payments/create">
      <PaymentsCreate />
    </PrivateRoute>
    <PrivateRoute path="/packagePayments">
      <PaymentsList type="PACKAGE" />
    </PrivateRoute>
    <PrivateRoute path="/payments">
      <PaymentsList type="AGREEMENT" />
    </PrivateRoute>
    <PrivateRoute path="/settlements/:userId/:id/show">
      <SettlementsShow />
    </PrivateRoute>
    <PrivateRoute path="/settlements">
      <SettlementsList />
    </PrivateRoute>
    <PrivateRoute path="/agreements">
      <AgreementsList />
    </PrivateRoute>
    <PrivateRoute path="/myAccount/edit">
      <MyAccountEdit />
    </PrivateRoute>
    <PrivateRoute path="/myAccount">
      <MyAccount />
    </PrivateRoute>
    <PrivateRoute path="/changePackage">
      <ChangePackage />
    </PrivateRoute>
    <PrivateRoute path="/changeView">
      <ChangeView />
    </PrivateRoute>
    <PrivateRoute path="/packages/create">
      <PackageCreate />
    </PrivateRoute>
    <PrivateRoute path="/packages/:id/edit">
      <PackageEdit />
    </PrivateRoute>
    <PrivateRoute path="/packages/:id/show">
      <PackageShow />
    </PrivateRoute>
    <PrivateRoute path="/packages">
      <PackagesList />
    </PrivateRoute>
    <PrivateRoute path="/premises">
      <PremisesAllList />
    </PrivateRoute>
    <PrivateRoute path="/receipts">
      <ReceiptsList />
    </PrivateRoute>
    <PrivateRoute path="/tags">
      <TagsList />
    </PrivateRoute>
    <PrivateRoute path="/receivables">
      <ReceivablesList />
    </PrivateRoute>
    <PrivateRoute path="/rodo">
      <RodoPage />
    </PrivateRoute>
    <Route exact path="/">
      <Redirect to="/login" />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
);

export default Routes;
