/* eslint-disable no-mixed-operators */
import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../Icon';

const RowActions = ({
  record,
  resource,
  customBaseUrlKey,
  hideEdit,
  hideShow,
  showIcon,
  editIcon,
  showAlert = () => false,
  customRowActions,
  calculateBaseUrl = false,
  redirectState,
}) => {
  let baseUrl = `${resource}/${record.id}`;

  if (customBaseUrlKey === 'premisesAll') {
    baseUrl = `investments/${record.investmentId}/premises/${record.id}`;
  }

  if (customBaseUrlKey === 'clientTenantsPremises') {
    baseUrl = `investments/${record.investmentId}/premises/${record.id}`;
  }
  if (customBaseUrlKey === 'clientTenantsFaults') {
    baseUrl = `faults/${record.investmentId}/fault/${record.id}`;
  }

  if (customBaseUrlKey === 'settlementGroups') {
    baseUrl = `settlements/${record.userId}/${record.id}`;
  }

  if (resource === 'faults') {
    baseUrl = `faults/${record.investmentId}/fault/${record.id}`;
  }
  if (resource === 'payments') {
    baseUrl = `payments/${record.investmentId}/premises/${record.premisesId}/agreement/${record.agreementId}/payment/${record.id}`;
  }

  if (resource === 'paymentsAGREEMENT' || resource === 'paymentsPACKAGE') {
    baseUrl = `payments/${record.investmentId}/premises/${record.premisesId}/agreement/${record.agreementId}/payment/${record.id}`;
  }

  if ((resource === 'paymentsAGREEMENT' || resource === 'paymentsPACKAGE') && record.status === 'PAID') {
    // eslint-disable-next-line no-param-reassign
    hideShow = true;
  }

  if (resource === 'paymentsPACKAGE' && record.status !== 'PAID' && record.type === 'PACKAGE') {
    // eslint-disable-next-line no-param-reassign
    hideShow = false;
  }

  if (resource === 'paymentsPACKAGE' && record.type === 'PACKAGE') {
    // eslint-disable-next-line no-param-reassign
    hideEdit = true;
  }

  if (calculateBaseUrl !== false) {
    baseUrl = calculateBaseUrl(record);
  }

  return (
    <div className="datagrid-row-actions">
      <Space size="middle">
        {showAlert(record) && (
          <Tooltip title="Termin ważności dowodu osobistego wygasł!">
            <Icon name="important" style={{ color: 'red', fontSize: '18px' }} />
          </Tooltip>
        )}
        {customRowActions && (
          customRowActions.map((customAction) => customAction(record))
        )}
        {!hideEdit && (
          <Link
            to={{
              pathname: `/${baseUrl}/edit`,
              state: redirectState,
            }}
          >
            <Button className="button-no-border">
              <Icon name={editIcon} />
            </Button>
          </Link>
        )}
        {!hideShow && (
          <Link
            to={{
              pathname: `/${baseUrl}/show`,
              state: redirectState,
            }}
          >
            <Button className="button-no-border">
              <Icon name={showIcon} />
            </Button>
          </Link>
        )}
      </Space>
    </div>
  );
};

RowActions.defaultProps = {
  showIcon: 'arrow-short-right',
  editIcon: 'edit',
};

export default RowActions;
