import BaseService from './baseService';

class FaultImagesService extends BaseService {
  constructor(investmentId, faultGroupId, faultId) {
    super();
    const roles = localStorage.getItem('roles');

    this.url = `/api/vendor/investment/${investmentId}/faultGroup/${faultGroupId}/fault/${faultId}/image`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/faultGroup/${faultGroupId}/fault/${faultId}/image`;
    }
  }
}

export default FaultImagesService;
