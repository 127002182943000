/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  PageHeader, Row, Col, Tag,
} from 'antd';
import moment from 'moment';
import { Link, useParams, useHistory } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import faultGroupHistoryService from '../../services/faultGroupHistoryService';
import faultsStatusMap from '../../helpers/faultsStatusMap';
import tenantChangesFinanceStatusMap from '../../helpers/tenantChangesFinanceStatusMap';
import faultFieldMap from '../../helpers/faultFieldMap';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Price from '../../components/Price';

const Show = () => {
  const { investmentId, faultGroupId } = useParams();
  const history = useHistory();
  const [faultGroup, setFaultGroup] = useState({});
  const [changeHistory, setChangeHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const promises = [];
    const FaultGroupService = new faultGroupService(investmentId, null);

    promises.push(FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
      setFaultGroup(faultGroupData);
    }));

    const FaultGroupHistoryService = new faultGroupHistoryService(
      investmentId, faultGroupId,
    );

    promises.push(FaultGroupHistoryService.getList().then((faultChangeHistoryData) => {
      setChangeHistory(faultChangeHistoryData);
    }));

    Promise.all(promises).then(() => setLoading(false));
  }, [faultGroupId, investmentId]);

  const handleBack = () => history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/show`);

  const HeaderTitle = () => {
    if (!faultGroup.name) {
      return <span>Historia</span>;
    }
    return (
      <span>
        Historia:
        {' '}
        <span className="header-subtitle">{faultGroup.name}</span>
      </span>
    );
  };

  const HistoryEntry = ({ entry }) => {
    const ChangeEntry = ({ change }) => {
      const propertyFormatter = (name, value) => {
        if (name === 'status') {
          return faultsStatusMap(value);
        }

        if (name === 'paymentStatus') {
          return tenantChangesFinanceStatusMap(value);
        }

        if (name === 'fixDate' || name === 'addDate') {
          return moment(value).format('YYYY-MM-DD');
        }

        if (name === 'amount' || name === 'paid') {
          return <Price value={value} showEmpty />;
        }

        return value;
      };

      return (
        <div className="tenant-changes-history-list-entry-changes-entry">
          <Row gutter={20}>
            <Col xs={24} sm={8}>{faultFieldMap(change.propertyName)}</Col>
            <Col xs={24} sm={16}>
              {change.propertyValueOld !== undefined
              && change.propertyValueOld !== null
              && change.propertyValueOld !== ''
              && change.propertyValueOld.length !== 0 && (
                <span className="tenant-changes-history-list-entry-changes-entry-previous">
                  {Array.isArray(change.propertyValueOld) ? (
                    <>
                      {change.propertyValueOld.map((property) => (
                        <>
                          <Tag>{propertyFormatter(change.propertyName, property)}</Tag>
                        </>
                      ))}
                      <Icon name="arrow-short-right" />
                    </>
                  ) : (
                    <>
                      <Tag>{propertyFormatter(change.propertyName, change.propertyValueOld)}</Tag>
                      <Icon name="arrow-short-right" />
                    </>
                  )}
                </span>
              )}
              {change.propertyValueNew ? (
                Array.isArray(change.propertyValueNew) ? (
                  change.propertyValueNew.map((property) => (
                    <>
                      <Tag>{propertyFormatter(change.propertyName, property)}</Tag>
                    </>
                  ))
                ) : (
                  <>
                    <Tag>{propertyFormatter(change.propertyName, change.propertyValueNew)}</Tag>
                  </>
                )

              ) : (
                <Tag color="error">Pusto</Tag>
              )}
            </Col>
          </Row>
        </div>
      );
    };

    if (entry.operation === 'UPDATE' && entry.changes.length === 0) {
      return '';
    }

    return (
      <div className="tenant-changes-history-list-entry">
        <Row gutter={20}>
          <Col xs={24} sm={4}>
            {moment(entry.createdAt).format('YYYY-MM-DD')}
          </Col>
          <Col xs={24} sm={4}>
            {entry.type === 'FAULT_GROUP' ? (
              <Link to={`/faultGroup/${investmentId}/faultGroup/${entry.groupId}/show`}>
                {entry.faultName}
              </Link>
            ) : (
              entry.operation === 'DELETE' && entry.type === 'FAULT' ? (
                <Link to={`/faultGroup/${investmentId}/faultGroup/${entry.groupId}/show`}>
                  {entry.faultName}
                </Link>
              ) : (
                <Link to={`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${entry.faultId}/show`}>
                  {entry.faultName}
                </Link>
              )
            )}
          </Col>
          <Col xs={24} sm={4}>
            {entry.userRoles.includes('ROLE_WORKER') ? (
              <Link to={`/workers/${entry.userId}/show`}>
                {entry.userName}
              </Link>
            ) : (
              entry.userName
            )}
          </Col>
          <Col xs={24} sm={12}>
            {entry.operation !== 'DELETE' && (
              entry.changes.map((change) => (<ChangeEntry change={change} />))
            )}
            {entry.operation === 'ADD' && (
              <Row gutter={20}>
                <Col xs={24} sm={8} />
                <Col xs={24} sm={16}>
                  <Tag color="success">Dodano</Tag>
                </Col>
              </Row>
            )}
            {entry.operation === 'DELETE' && (
              <Row gutter={20}>
                <Col xs={24} sm={8}>
                  {entry.type === 'FAULT_ATTACHMENT' && (
                    <span>Załącznik</span>
                  )}
                  {entry.type === 'FAULT' && (
                    <span>Podział zmian</span>
                  )}
                </Col>
                <Col xs={24} sm={16}>
                  <span className="tenant-changes-history-list-entry-changes-entry-previous">
                    <Tag>
                      {entry.type === 'FAULT_ATTACHMENT' && (
                        entry.attachmentName
                      )}
                      {entry.type === 'FAULT' && (
                        entry.faultName
                      )}
                    </Tag>
                    <Icon name="arrow-short-right" />
                  </span>
                  <Tag color="error">Usunięto</Tag>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="show-layout tenantChanges-show">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name="arrow-simply-left" />}
          title={<HeaderTitle />}
          className="page-header-extra-2"
        >
          <div className="premises-show">
            <div className="tenant-changes-history">
              <div className="tenant-changes-history-header">
                <Row gutter={20}>
                  <Col xs={24} sm={4} className="tenant-changes-history-header-row">Data</Col>
                  <Col xs={24} sm={4} className="tenant-changes-history-header-row">Podział zmian</Col>
                  <Col xs={24} sm={4} className="tenant-changes-history-header-row">Użytkownik</Col>
                  <Col xs={24} sm={4} className="tenant-changes-history-header-row">Operacja</Col>
                </Row>
              </div>
              <div className="tenant-changes-history-list">
                {changeHistory.map((historyEntry) => (
                  <HistoryEntry entry={historyEntry} />
                ))}
              </div>
            </div>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
