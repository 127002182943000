import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Button, Checkbox, PageHeader, Row, Col, Card, Upload, Divider, message, Switch,
} from 'antd';
import moment from 'moment';

import developersService from '../../services/developersService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import IdentityConfirmForm from '../../components/IdentityConfirmForm';
import AdminPackageForm from '../../components/accountEditComponents/AdminPackageForm';

import Tabs from '../../components/Tabs';

const { InputNumber } = Input;
const { TabPane } = Tabs;

const Edit = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [userAvatar, setUserAvatar] = useState('');
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();
  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }

  useEffect(() => {
    developersService.getOne(id).then((data) => {
      if (data?.identity?.idExpireDate) {
        // eslint-disable-next-line no-param-reassign
        data = {
          ...data,
          identity: {
            ...data.identity,
            idExpireDate: moment(data.identity.idExpireDate),
          },
        };
      }
      setUserData(data);
      setUserAvatar(data.avatar);
      const defaultValues = data;
      defaultValues.canSell = true;
      defaultValues.canRent = false;

      if (data.roles && data.roles.includes('ROLE_TENANT')) {
        defaultValues.canRent = true;
      }
      if (data.correspondenceAddress) {
        setShowCorrespondence(true);
        defaultValues.differentAddress = true;
      }
      form.setFieldsValue(defaultValues);
    });
  }, [form, id]);

  const onFinish = (values) => {
    const roles = [];
    if (values.canSell) {
      roles.push('ROLE_DEVELOPER');
    }

    if (values.canRent) {
      roles.push('ROLE_TENANT');
    }
    if (roles.length === 0) {
      roles.push('ROLE_DEVELOPER');
    }
    const data = { ...values, roles };

    if (!data.differentAddress) {
      data.correspondenceAddress = null;
    }
    developersService.update(id, data).then((response) => {
      if (response.id) {
        history.push(`/developers/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
      if (err?.response?.status === 409) {
        form.setFields([
          {
            name: 'username',
            errors: ['Podany login juz istnieje'],
          },
        ]);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadAvatar = (data) => {
    const formData = new FormData();
    formData.append('avatar', data.file);
    developersService.uploadAvatar(id, formData).then((response) => {
      setUserAvatar(response.avatar);
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const sendPasswordAction = () => {
    const password = form.getFieldValue('password');
    const confirm = form.getFieldValue('confirm');
    if (password !== confirm) {
      return;
    }

    if (password.length < 8) {
      return;
    }
    developersService.sendPassword(id, { password }).then(() => {
      message.success('Hasło zostało wysłane');
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleOk = () => {
    developersService.delete(id).then(() => history.push('/developers')).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleCancel = () => {
    history.push('/developers');
  };

  const HeaderTitle = () => {
    if (!userData.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{userData.name}</span>
      </span>
    );
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/developers')}
        title={<HeaderTitle />}
        extra={[
          <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
            <Icon name="delete" style={{ marginRight: '5px' }} />
            {' '}
            Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Informacje" key="form">
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="form-section">
                          <h2>Miniaturka</h2>
                          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                            <Upload.Dragger
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              customRequest={uploadAvatar}
                              action="avatar"
                              beforeUpload={beforeUpload}
                            >
                              <div className="upload-avatar-wrapper">
                                {userAvatar && (
                                  <img src={`${baseUrl}${userAvatar}`} alt="avatar" />
                                )}
                                <Button size="large" className="change-button" type="primary">
                                  Zmień
                                  {' '}
                                  <Icon name="edit" />
                                </Button>
                              </div>
                            </Upload.Dragger>
                          </Form.Item>
                          <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                        </div>
                        <div className="form-section">
                          <h2>Dane podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name="nameDeveloper"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Nazwa dewelopera" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Dane dewelopera</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Nazwa firmy" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="nip"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input.MaskedInput mask="111-111-11-11" size="large" placeholder="NIP" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Adres siedziby</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Ulica" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Numer" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'local']}
                              >
                                <Input size="large" placeholder="Mieszkanie" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Miejscowość" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                              <Checkbox
                                onChange={correspondenceShow}
                              >
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size="large" placeholder="Ulica" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size="large" placeholder="Numer" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input size="large" placeholder="Mieszkanie" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input size="large" placeholder="Miejscowość" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className="form-section">
                          <h2>Dane kontaktowe</h2>
                          <Form.Item
                            name="email"
                            rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
                          >
                            <Input size="large" placeholder="E-Mail" />
                          </Form.Item>
                          <Form.Item
                            name="mobile"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <InputNumber size="large" placeholder="Telefon" />
                          </Form.Item>
                          <Form.Item name="enabled" valuePropName="checked" noStyle>
                            <Checkbox>Aktywny</Checkbox>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Card className="create-form-password-card">
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className="form-section">
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name="username"
                              rules={[{ required: true, message: 'Proszę uzupełnić login' }]}
                            >
                              <Input size="large" placeholder="Login" />
                            </Form.Item>
                            <Form.Item
                              name="password"
                              rules={[{ message: 'Pole wymagane' },
                                { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
                            >
                              <Input
                                type="password"
                                size="large"
                                placeholder="Hasło"
                              />
                            </Form.Item>
                            <Form.Item
                              name="confirm"
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject('Hasło nieprawidłowe');
                                  },
                                }),
                              ]}
                            >
                              <Input.Password size="large" placeholder="Powtórz hasło" />
                            </Form.Item>
                            <Form.Item>
                              <Button className="create-form-button-send-password" size="large" onClick={() => sendPasswordAction()}>
                                Wyślij login i hasło
                                {' '}
                                <Icon name="access" />
                              </Button>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className="send-password-image-wrapper">
                            <img src={image} alt="send password icon" />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>
                {(userData.roles && userData.roles.indexOf('ROLE_TENANT') !== -1) && (
                  <TabPane tab="Potwierdzenie tożsamości" key="identity" forceRender>
                    <IdentityConfirmForm />
                  </TabPane>
                )}
                <TabPane tab="Uprawnienia" key="permissions" forceRender>
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="permissions">
                          <div className="form-section">
                            <h2>Zarządzanie nieruchomościami</h2>
                            <Form.Item
                              label="Sprzedaż"
                              name="canSell"
                              valuePropName="checked"
                              hidden
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label="Najem"
                              name="canRent"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Pakiet" key="packages" forceRender>
                  <div className="create-form-wrapper">
                    <AdminPackageForm userData={userData} filterType="DEVELOPER" resourceService={developersService} />
                  </div>
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
