import React, { useState, useEffect } from 'react';
import {
  Layout, Menu, Button, Dropdown, Popover,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';

import Notifications from './Notifications';
import Package from './Package';
import userService from '../../services/userService';
import notificationsService from '../../services/notificationsService';
import authService from '../../services/authService';
import Icon from '../../components/Icon';
import notificationFull from '../../static/images/notification-full.png';

const { Header } = Layout;

const LoggedHeader = ({
  shouldShowMenu, userData, setUserData, collapsed, setCollapsed,
}) => {
  const history = useHistory();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsIcon, setNotificationsIcon] = useState('notification-empty');

  useEffect(() => {
    notificationsService.getList({
      sortField: 'createDate',
      sortOrder: 'descend',
      pagination: {
        pageSize: 30,
      },
    }).then((notificationsData) => {
      setNotifications(notificationsData.content);

      const notReaded = notificationsData.content
        .filter((notificationData) => !notificationData.isRead);
      if (notReaded.length > 0) {
        setNotificationsIcon('notification-full');
      }
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      notificationsService.getList({
        sortField: 'createDate',
        sortOrder: 'descend',
        pagination: {
          pageSize: 30,
        },
      }).then((notificationsData) => {
        setNotifications(notificationsData.content);

        const notReaded = notificationsData.content
          .filter((notificationData) => !notificationData.isRead);
        if (notReaded.length > 0) {
          setNotificationsIcon('notification-full');
        }
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const logout = () => {
    authService.logout().then(() => {
      history.push('/login');
    });
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const refreshNotifications = () => {
    notificationsService.getList({
      sortField: 'createDate',
      sortOrder: 'descend',
      pagination: {
        pageSize: 10,
      },
    }).then((notificationsData) => {
      setNotifications(notificationsData.content);

      const notReaded = notificationsData.content
        .filter((notificationData) => !notificationData.isRead);
      if (notReaded.length > 0) {
        setNotificationsIcon('notification-full');
      } else {
        setNotificationsIcon('notification-empty');
      }
    });
  };

  const refreshUserData = () => {
    userService.getInfo().then((data) => {
      setUserData(data);
      localStorage.setItem('roles', JSON.stringify(data.roles));
    });
  };

  const menu = (roles = []) => (
    <Menu style={{ padding: '10px' }}>
      {!roles.includes('ROLE_ADMIN') && (
        <Menu.Item key="1" style={{ color: '#587999', fontWeight: 600 }} icon={<Icon name="user" style={{ marginRight: '5px', color: '#587999' }} />}>
          <Link to="/myAccount" className="premises-custom-create-link">
            Moje konto
          </Link>
        </Menu.Item>
      )}
      {roles.includes('ROLE_ADMIN') && (
        <Menu.Item key="2" style={{ color: '#587999', fontWeight: 600 }} icon={<Icon name="user" style={{ marginRight: '5px', color: '#587999' }} />}>
          <Link to="/myAccount/edit" className="premises-custom-create-link">
            Moje konto
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="3" style={{ color: '#587999', fontWeight: 600 }} icon={<Icon name="palette" style={{ marginRight: '5px', color: '#587999' }} />}>
        <Link to="/changeView" className="premises-custom-create-link">
          Dostosuj wygląd
        </Link>
      </Menu.Item>
      <Menu.Item key="4" style={{ color: '#EC434C', fontWeight: 600 }} onClick={() => logout()} icon={<Icon name="logout" style={{ marginRight: '5px' }} />}>
        Wyloguj
      </Menu.Item>
    </Menu>
  );

  const handleNotificationsVisibleChange = (visible) => {
    setNotificationsVisible(visible);
  };

  const hideNotifications = () => {
    setNotificationsVisible(false);
  };

  return (
    <Header className="site-layout-background site-layout-header">
      <Icon name="burger-menu" className="trigger custom-menu-trigger-action" onClickAction={toggle} />
      <div className="site-layout-header-actions">
        {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT']) && <Package userData={userData} refreshUserData={refreshUserData} />}
        <Popover
          placement="bottom"
          overlayClassName="notifications-popover"
          content={(
            <Notifications
              data={notifications}
              refresh={refreshNotifications}
              hideNotifications={hideNotifications}
            />
                )}
          trigger="click"
          visible={notificationsVisible}
          onVisibleChange={handleNotificationsVisibleChange}
        >
          <Button className="button-no-border header-notifications-button">
            {notificationsIcon === 'notification-empty' ? (
              <Icon name={notificationsIcon} />
            ) : (
              <img src={notificationFull} alt="notifications" className="notifications-active-icon" />
            )}
          </Button>
        </Popover>
        <Dropdown overlay={menu(userData.roles)} overlayClassName="header-menu-wrapper" trigger="click" placement="bottomLeft">
          <Button className="button-no-border header-options-button">
            <Icon name="user" />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default LoggedHeader;
