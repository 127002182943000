import React from 'react';
import {
  Modal, Dropdown, Button, Menu, message,
} from 'antd';
import json2csv from 'json2csv';
import moment from 'moment';
import jsPDF from 'jspdf';
// eslint-disable-next-line no-unused-vars
import autoTable from 'jspdf-autotable';
import cloneDeep from 'lodash/cloneDeep';

import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DownOutlined } from '@ant-design/icons';
import '../../static/Overpass-Regular-normal';
import PaymentsS from '../../services/paymentsService';
import FaultsS from '../../services/faultsService';
import TenantChangesS from '../../services/tenantChangeGroups';
import UserSettlementsGroupS from '../../services/userSettlementsGroupService';
import tranchesService from '../../services/tranchesService';
import Icon from '../Icon';
import PermisesService from '../../services/premisesService';
import FaultGroupService from '../../services/faultGroupService';

const { confirm } = Modal;

const MassActions = ({
  dataProvider, selectedRowKeys, resource, data, columns, title = 'Lista', canEdit, hideDelete,
}) => {
  const history = useHistory();
  const handleOk = () => {
    const deletePromises = [];

    if (resource === 'settlements') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new UserSettlementsGroupS(selectedRow.userId);
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'receipts') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        let customDataProvider;
        if (selectedRow.type === 'TENANT_CHANGE') {
          customDataProvider = new TenantChangesS(
            selectedRow.investmentId, selectedRow.premisesId,
          );
        } else {
          customDataProvider = new tranchesService(
            selectedRow.investmentId, selectedRow.premisesId,
          );
        }

        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'paymentsAGREEMENT') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new PaymentsS(
          selectedRow.investmentId, selectedRow.premisesId, selectedRow.agreementId,
        );
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'faults') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new FaultsS(selectedRow.investmentId);
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'tenantChangeGroup') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new TenantChangesS(
          selectedRow.investmentId, selectedRow.premisesId,
        );
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'premisesAll') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new PermisesService(
          selectedRow.investmentId,
        );
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else if (resource === 'faultGroup') {
      const promises = data.filter((entry) => selectedRowKeys
        .includes(entry.id)).map((selectedRow) => {
        const customDataProvider = new FaultGroupService(
          selectedRow.investmentId,
        );
        return customDataProvider.delete(selectedRow.id).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });

      deletePromises.push(promises);
    } else {
      deletePromises.push(dataProvider.deleteMany(selectedRowKeys).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      }));
    }

    Promise.all(deletePromises).then(() => {
      message.success('Pomyślnie usunięto rekord(y)');
      history.go(0);
    }).catch(() => {
      message.error('Wystąpił błąd podczas usuwania rekordów');
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: `Usunąć ${selectedRowKeys.length} pozycje?`,
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        handleOk();
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showDeleteConfirm} style={{ color: '#EC434C' }} icon={<Icon name="delete" style={{ marginRight: '5px' }} />}>
        Usuń
      </Menu.Item>
    </Menu>
  );

  const exportRows = (type) => {
    const tempArray = cloneDeep(data);
    const rowsToExport = tempArray.filter((entry) => selectedRowKeys.includes(entry.id));
    const visibleColumns = columns.filter((entry) => entry.visible && entry.export !== false);
    const columnsToKeep = visibleColumns.map((column) => column.key);
    const fields = visibleColumns.map((column) => ({
      label: column.title,
      value: column.key,
    }));

    const pdfFields = visibleColumns.map((column) => ({
      header: column.title,
      dataKey: column.key,
    }));

    const parsedData = rowsToExport.map((row) => {
      const temp = row;
      Object.keys(rowsToExport).forEach((key) => {
        if (!columnsToKeep.includes(key)) {
          delete temp[key];
        }
      });

      const exportRenderers = visibleColumns.filter((entry) => entry.exportRender);

      if (!exportRenderers) {
        return temp;
      }

      exportRenderers.forEach((renderer) => {
        temp[renderer.key] = renderer.exportRender(temp[renderer.key], temp);
      });

      return temp;
    });

    const fileName = `${title}-${moment().format('YYYY-MM-DD HH:mm:ss').toString()}`;

    if (type === 'xls') {
      const csv = json2csv.parse(parsedData, { fields });
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      a.download = `${fileName}.csv`;
      a.click();
    } else {
      const doc = new jsPDF({
        orientation: 'landscape',

      });
      doc.setFont('Overpass-Regular');
      doc.text(title, 14, 20);
      doc.autoTable({
        columns: pdfFields,
        margin: { top: 10 },
        body: parsedData,
        tableWidth: 'auto',
        startY: 40,
        theme: 'striped',
        styles: {
          font: 'Overpass-Regular',
          fontStyle: 'normal',
        },
        headStyles: { fontStyle: 'normal' },
      });
      doc.save(`${fileName}.pdf`);
    }

    // history.go(0);
  };

  const exportMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => exportRows('xls')} icon={<Icon name="xls" style={{ marginRight: '5px' }} />}>
        XLS
      </Menu.Item>
      <Menu.Item key="2" onClick={() => exportRows('pdf')} icon={<Icon name="pdf" style={{ marginRight: '5px' }} />}>
        PDF
      </Menu.Item>
    </Menu>
  );

  return (
    <span>
      <Dropdown overlay={exportMenu} trigger="click" placement="bottomCenter" style={{ marginRight: '5px' }}>
        <Button className="button-secondary" style={{ marginRight: '5px' }}>
          <Icon name="download" />
        </Button>
      </Dropdown>
      {canEdit && !hideDelete && (
        <Dropdown overlay={menu} trigger="click" placement="bottomCenter">
          <Button className="button-secondary">
            Akcje
            {' '}
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </span>
  );
};

export default MassActions;
