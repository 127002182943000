import React, { useState, useEffect } from 'react';
import {
  Row, Col, Tooltip, Form, DatePicker,
} from 'antd';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';

import StatsS from '../../../services/statsService';
import Spin from '../../../components/Spin';
import Icon from '../../../components/Icon';
import premiseTypeMap from '../../../helpers/premiseTypeMap';

const useQuery = () => new URLSearchParams(useLocation().search);

const PremisesChart = ({ investmentType }) => {
  const query = useQuery();
  const id = query.get('id') || null;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [chartConfig, setChartConfig] = useState({});

  const options = {
    legend: {
      display: false,
    },
  };

  useEffect(() => {
    const StatsService = new StatsS(id);
    StatsService.getPremises(id, {
      dateFrom: moment().subtract(1, 'years').startOf('month').format('YYYY-MM-DD'),
      dateTo: moment().endOf('month').format('YYYY-MM-DD'),
      investmentType,
    }).then((data) => {
      const baseResponse = [
        {
          type: 'SALE',
          label: premiseTypeMap('SALE'),
          color: '#5353F4',
          count: 0,
        },
        {
          type: 'RENT',
          label: premiseTypeMap('RENT'),
          color: '#5353F4',
          count: 0,
        },
        {
          type: 'STORAGE',
          label: premiseTypeMap('STORAGE'),
          color: '#F0F4F6',
          count: 0,
        },
        {
          type: 'PARKING',
          label: premiseTypeMap('PARKING'),
          color: '#ED565E',
          count: 0,
        },
        {
          type: 'SERVICE',
          label: premiseTypeMap('SERVICE'),
          color: '#15D55E',
          count: 0,
        },
      ];

      const baseResponseWithData = baseResponse.map((elem) => {
        if (!data[elem.type]) {
          return elem;
        }

        return {
          ...elem,
          count: data[elem.type],
        };
      });

      const premisesChartConfig = {
        labels: baseResponseWithData.map((elem) => elem.label),
        datasets: [{
          data: baseResponseWithData.map((elem) => elem.count),
          backgroundColor: baseResponseWithData.map((elem) => elem.color),
          hoverBackgroundColor: baseResponseWithData.map((elem) => elem.color),
        }],
      };

      setChartConfig(premisesChartConfig);
      setLoading(false);
    });
  }, [id, investmentType]);

  const handleDates = () => {
    const dateFrom = form.getFieldValue('dateFrom');
    const dateTo = form.getFieldValue('dateTo');
    const params = {
      investmentType,
    };
    if (dateFrom) {
      params.dateFrom = dateFrom.startOf('month').format('YYYY-MM-DD');
    }
    if (dateTo) {
      params.dateTo = dateTo.endOf('month').format('YYYY-MM-DD');
    } else if (dateFrom) {
      params.dateTo = moment().endOf('month').format('YYYY-MM-DD');
    }

    const StatsService = new StatsS(id);

    const endpoint = StatsService.getPremises(id, params);

    endpoint.then((data) => {
      const baseResponse = [
        {
          type: 'SALE',
          label: premiseTypeMap('SALE'),
          color: '#5353F4',
          count: 0,
        },
        {
          type: 'RENT',
          label: premiseTypeMap('RENT'),
          color: '#5353F4',
          count: 0,
        },
        {
          type: 'STORAGE',
          label: premiseTypeMap('STORAGE'),
          color: '#F0F4F6',
          count: 0,
        },
        {
          type: 'PARKING',
          label: premiseTypeMap('PARKING'),
          color: '#ED565E',
          count: 0,
        },
        {
          type: 'SERVICE',
          label: premiseTypeMap('SERVICE'),
          color: '#15D55E',
          count: 0,
        },
      ];

      const baseResponseWithData = baseResponse.map((elem) => {
        if (!data[elem.type]) {
          return elem;
        }

        return {
          ...elem,
          count: data[elem.type],
        };
      });

      const premisesChartConfig = {
        labels: baseResponseWithData.map((elem) => elem.label),
        datasets: [{
          data: baseResponseWithData.map((elem) => elem.count),
          backgroundColor: baseResponseWithData.map((elem) => elem.color),
          hoverBackgroundColor: baseResponseWithData.map((elem) => elem.color),
        }],
      };

      setChartConfig(premisesChartConfig);
      setLoading(false);
    });
  };

  return (
    <div className="stats-elem">
      <div className="title">
        <h2>
          Sprzedaż lokali
          {' '}
          <Tooltip title="Suma sprzedanych poszczególnych lokali w danym okresie">
            <Icon name="more-info" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </h2>
      </div>
      <div className="content">
        {loading ? (
          <div className="loading"><Spin /></div>
        ) : (
          <Row>
            <Col xs={24}>
              <div className="form">
                <Form
                  form={form}
                  name="create"
                  className="create-form"
                  autoComplete="off"
                  layout="inline"
                >
                  <Row>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name="dateFrom"
                        label="Od"
                        rules={[{ required: true, message: 'Pole wymagane' }]}
                      >
                        <DatePicker
                          defaultValue={moment().subtract(1, 'years').startOf('month')}
                          size="small"
                          placeholder="Od"
                          picker="month"
                          onChange={() => handleDates()}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name="dateTo"
                        label="Do"
                        rules={[{ required: true, message: 'Pole wymagane' }]}
                      >
                        <DatePicker
                          defaultValue={moment().endOf('month')}
                          size="small"
                          placeholder="Do"
                          picker="month"
                          onChange={() => handleDates()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <Pie
                data={chartConfig}
                options={options}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div className="stats-elem-premises-legend">
                <div className="legend-item">
                  <span className="dot premises" />
                  Mieszkania
                </div>
                <div className="legend-item">
                  <span className="dot services" />
                  Lokale usługowe
                </div>
                <div className="legend-item">
                  <span className="dot parking" />
                  Miejsca postojowe
                </div>
                <div className="legend-item">
                  <span className="dot storage" />
                  Komórki lokatorskie
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default PremisesChart;
