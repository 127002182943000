import React from 'react';
import { Form, Button, Checkbox } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import authService from '../../services/authService';
import { Input, Icon } from '../../components';

const Login = () => {
  const isDemo = process.env.REACT_APP_DEMO_MODE === 'true';

  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    authService.login(values)
      .then(() => {
        history.push('/dashboard');
      })
      .catch(() => {
        form.setFields([
          {
            name: 'username',
            errors: [''],
          },
        ]);
        form.setFields([
          {
            name: 'password',
            errors: ['Niepoprawny login lub hasło'],
          },
        ]);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className="login-form-wrapper">
        <h2>Logowanie</h2>
        {isDemo && (
          <div className="demo-info">
            <Icon name="more-info" style={{ marginRight: '5px' }} />
            {' '}
            Aby zalogować się do systemu, wprowadź jedne z poniższych danych (login / hasło):
            <br />
            <span>Deweloper</span>
            {' '}
            - deweloper / 12345678
            <br />
            <span>Zarządca najmem</span>
            {' '}
            - zarzadca / 12345678
            <br />
            <span>Pracownik - sprzedaż</span>
            {' '}
            - pracownik / 12345678
            <br />
            <span>Pracownik - najem</span>
            {' '}
            - pracownik-najem / 12345678
            <br />
            <span>Najemca</span>
            {' '}
            - najemca / 12345678
            <br />
          </div>
        )}
        <Form
          name="login"
          form={form}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Pole wymagane' }]}
          >
            <Input size="large" placeholder="Login" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Pole wymagane' }]}
          >
            <Input.Password
              size="large"
              placeholder="Hasło"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Zapamiętaj mnie</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" className="login-form-button">
              Zaloguj się
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Link className="login-form-forgot" to="/resetPassword">
              Zapomniałeś hasła?
            </Link>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default Login;
