import React from 'react';
import { Tooltip } from 'antd';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import faultsService from '../../services/faultsService';
import faultsStatusMap from '../../helpers/faultsStatusMap';

const Dot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const List = ({ record: faultGroup, roles, redirectState }) => {
  const getWidth = (columnKey) => {
    try {
      return document.querySelectorAll(`[data-row-key="${faultGroup.id}"] td.datagrid-column-${columnKey}`).item(0).offsetWidth;
    } catch (error) {
      return undefined;
    }
  };

  const getSelectionWidth = () => {
    try {
      return `${document.querySelectorAll(`[data-row-key="${faultGroup.id}"] td.ant-table-selection-column`).item(0).offsetWidth}px`;
    } catch (error) {
      return undefined;
    }
  };
  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
      width: getWidth('name'),
    },
    {
      dataIndex: 'premisesType',
      visible: true,
      key: 'premisesType',
      sorter: false,
      width: getWidth('premisesType'),
    },
    {
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: false,
      width: getWidth('premisesName'),
    },
    {
      dataIndex: 'investmentName',
      visible: true,
      key: 'investmentName',
      sorter: false,
      width: getWidth('investmentName'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => faultsStatusMap(record.status),
      width: getWidth('status'),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
      width: getWidth('addDate'),
    },
    {
      title: 'Usunięto',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
      width: !roles.includes('ROLE_CLIENT_TENANT') && !roles.includes('ROLE_CLIENT') ? getWidth('fixDate') : undefined,
    },
  ];

  if (!roles.includes('ROLE_CLIENT_TENANT') && !roles.includes('ROLE_CLIENT')) {
    columns.push(
      {
        dataIndex: 'hide',
        visible: true,
        key: 'hide',
        width: getWidth('responsibleWorkers'),
      },
      {
        title: 'Kwota',
        dataIndex: 'amount',
        visible: true,
        key: 'amount',
        sorter: false,
        summary: true,
        render: (text, record) => (<Price value={record.amount} showEmpty />),
      },
    );
  }

  const customBaseUrl = (record) => `faultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault/${record.id}`;

  return (
    <div style={{ paddingLeft: getSelectionWidth() }}>
      <Datagrid
        resource="fault"
        dataProvider={new faultsService(
          faultGroup.investmentId, faultGroup.id,
        )}
        columns={columns}
        hideAllActions
        directResponse
        disableSelection
        calculateBaseUrl={customBaseUrl}
        disablePagination
        showHeader
        redirectState={redirectState}
      />
    </div>
  );
};

export default List;
