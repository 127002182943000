import React, { useState } from 'react';
import {
  Modal, Button, Form, Row, Col, Select,
} from 'antd';
import Icon from '../Icon';
import Input from '../InputComponent';

const { Option } = Input;

const FilterModal = (props) => {
  const { columns, handleFilterChange, customFilters } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        // eslint-disable-next-line no-param-reassign
        Object.keys(values).forEach((key) => (values[key] === undefined || values[key] === '') && delete values[key]);
        handleFilterChange(values);
        setModalVisible(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    handleFilterChange({});
    setModalVisible(false);
  };

  const filterInputs = columns.filter((column) => !!column.filterEnabled).map((column) => {
    let input = (<Input placeholder={column.title} size="large" />);
    if (column.filterType && column.filterType === 'select') {
      const options = Object.keys(column.filterOptions).map((filterKey) => (
        <Option key={filterKey} value={filterKey}>{column.filterOptions[filterKey]}</Option>
      ));

      input = (
        <Select
          placeholder={column.title}
          allowClear
          size="large"
        >
          {options}
        </Select>
      );
    }
    return (
      <Col xs={24} sm={12} key={column.key}>
        <Form.Item
          name={column.key}
          key={column.key}
        >
          {input}
        </Form.Item>
      </Col>
    );
  });

  const settedFilters = [
    ...filterInputs,
    ...customFilters,
  ];

  return (
    <>
      <Button className="button-secondary" onClick={() => setModalVisible(true)}>
        Filtry
        {' '}
        <Icon name="filtr" />
      </Button>
      <Modal
        title="Filtrowanie"
        centered
        visible={modalVisible}
        width={770}
        onCancel={() => setModalVisible(false)}
        footer={(
          <Row gutter={20} className="datagrid-filter-modal-button-wrapper">
            <Col xs={12} sm={12} style={{ textAlign: 'left' }}>
              <Button type="danger" size="large" onClick={() => onReset()}>
                <Icon name="refresh" style={{ marginRight: '5px' }} />
                {' '}
                Resetuj
              </Button>
            </Col>
            <Col xs={12} sm={12}>
              <Button type="primary" size="large" onClick={() => onFinish()}>
                Filtruj
              </Button>
            </Col>
          </Row>
        )}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={8}>
            {settedFilters}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FilterModal;
