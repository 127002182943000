import React from 'react';
import { Tooltip } from 'antd';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import tenantChangesService from '../../services/tenantChangeService';
import tenantChangesStatusMap from '../../helpers/tenantChangesStatusMap';

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const List = ({ record: tenantChangeGroup, canEdit }) => {
  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => tenantChangesStatusMap(record.status),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'changeDate',
      visible: true,
      key: 'changeDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      visible: true,
      key: 'expirationDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Opłacono',
      dataIndex: 'paymentDate',
      visible: true,
      key: 'paymentDate',
      sorter: false,
      render: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
      exportRender: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      summary: true,
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const customBaseUrl = (record) => `tenantChangeGroup/${tenantChangeGroup.investmentId}/premises/${tenantChangeGroup.premisesId}/tenantChangeGroup/${tenantChangeGroup.id}/tenantChange/${record.id}`;

  return (
    <div>
      <Datagrid
        resource="tenantChange"
        dataProvider={new tenantChangesService(
          tenantChangeGroup.investmentId, tenantChangeGroup.premisesId, tenantChangeGroup.id,
        )}
        columns={columns}
        canEdit={canEdit}
        hideEdit={!canEdit}
        hideAllActions
        directResponse
        disableSelection
        calculateBaseUrl={customBaseUrl}
        disablePagination
        showHeader
      />
    </div>
  );
};

export default List;
