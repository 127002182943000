import React, { useState, useEffect } from 'react';
import {
  Row, Col, Button,
} from 'antd';
import moment from 'moment';
import FaultsS from '../../services/faultsService';
import faultGroupService from '../../services/faultGroupService';
import notificationsService from '../../services/notificationsService';
import Icon from '../../components/Icon';

const SingleNotification = ({ data, refresh }) => {
  const { isRead, type, referenceIds = {} } = data;
  const investmentId = referenceIds?.investmentId || '';
  const faultId = referenceIds?.faultId || '';
  const faultGroupId = referenceIds?.faultGroupId || '';
  const [fault, setFault] = useState({});

  useEffect(() => {
    if (type === 'FAULT_DEVELOPER_VERIFICATION') {
      const FaultsService = new FaultsS(
        investmentId, faultGroupId,
      );
      FaultsService.getOne(faultId).then((faultData) => {
        setFault(faultData);
      });
    }

    if (type === 'FAULT_CLIENT_VERIFICATION') {
      const FaultsService = new faultGroupService(investmentId);
      FaultsService.getOne(faultGroupId).then((faultData) => {
        setFault(faultData);
      });
    }
  }, [type, faultId, faultGroupId, investmentId]);

  let isReadClassname = '';
  if (!isRead) {
    isReadClassname = 'notification--unread';
  }

  let iconName = '';
  let iconStyle = {};

  switch (type) {
    case 'PAYMENT_NEW':
    case 'PAYMENT_EXPIRED':
    case 'TRANCHE_EXPIRED':
    case 'PAYMENT_REMINDER':
      iconName = 'payments';
      break;
    case 'FAULT_DEVELOPER_DECISION_NEGATIVE':
    case 'FAULT_DEVELOPER_DECISION_POSITIVE':
    case 'FAULT_CLIENT_DECISION_NEGATIVE':
    case 'FAULT_CLIENT_DECISION_POSITIVE':
    case 'FAULT_DEVELOPER_VERIFICATION':
    case 'FAULT_RESPONSIBLE_PERSON':
    case 'FAULT_GROUP_RESPONSIBLE_PERSON':
    case 'FAULT_CLIENT_VERIFICATION':
    case 'FAULT_NEW':
    case 'FAULT_REPORTED_RESOLVE_REMINDER':
      iconName = 'fault-single';
      break;
    case 'PREMISES_STATUS':
      iconName = 'apartments';
      break;
    case 'AGREEMENT_NEW':
    case 'AGREEMENT_END':
      iconName = 'file';
      break;
    default:
      iconName = '';
  }

  if (type.indexOf('EXPIRED') !== -1) {
    iconStyle = { color: 'red' };
  }

  const faultDecisionAction = (isCorrect) => {
    const FaultsService = new FaultsS(investmentId, faultGroupId);
    FaultsService.updateConfirmation(fault.id, {
      isCorrect,
    }).then(() => {
      FaultsService.getOne(fault.id).then((faultData) => {
        setFault(faultData);
        notificationsService.updateFields(data.id, {
          isButtonClicked: true,
        }).then(() => refresh());
      });
    }).catch((err) => {
      if (err && err.message === 'demo') {
        // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const faultGroupDecisionAction = (isConfirmed) => {
    const FaultsService = new faultGroupService(investmentId);
    FaultsService.updateConfirmation(faultGroupId, isConfirmed).then(() => {
      FaultsService.getOne(faultGroupId).then((faultData) => {
        setFault(faultData);
        notificationsService.updateFields(data.id, {
          isButtonClicked: true,
        }).then(() => refresh());
      });
    }).catch((err) => {
      if (err && err.message === 'demo') {
        // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  return (
    <div className={`notification ${isReadClassname}`} role="presentation">
      <Row>
        <Col xs={2}>
          <div className="icon-wrapper">
            <Icon name={iconName} style={iconStyle} />
          </div>
        </Col>
        <Col xs={22}>
          <div className="content">
            <div className="description">{data.description}</div>
            {type === 'FAULT_DEVELOPER_VERIFICATION' && fault.isCorrect === null && data.isButtonClicked === false && (
              <div className="fault-actions">
                <Button size="small" type="primary" onClick={() => faultDecisionAction(true)} style={{ marginRight: '5px' }}>Potwierdź</Button>
                <Button size="small" className="button-secondary" onClick={() => faultDecisionAction(false)}>Odrzuć</Button>
              </div>
            )}
            {type === 'FAULT_CLIENT_VERIFICATION' && fault.isConfirmed === null && data.isButtonClicked === false && (
              <div className="fault-actions">
                <Button size="small" type="primary" onClick={() => faultGroupDecisionAction(true)} style={{ marginRight: '5px' }}>Potwierdź</Button>
                <Button size="small" className="button-secondary" onClick={() => faultGroupDecisionAction(false)}>Odrzuć</Button>
              </div>
            )}
            <div className="time">{moment(`${data.createDate}Z`).locale('pl_PL').startOf('minutes').fromNow()}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SingleNotification;
