import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import investmentsService from '../../services/investmentsService';
import PremisesServiceS from '../../services/premisesService';
import workersService from '../../services/workersService';
import userService from '../../services/userService';
import {
  Spin, Input, Icon, Tabs, deleteConfirm,
} from '../../components';
import Faults from './editComponents/Faults';
import { Tags } from './editComponents/Tags';

const {
  DatePicker, Select, SearchBox,
} = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit = () => {
  const { faultGroupId, investmentId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);
  const [fault, setFault] = useState({});
  const [userData, setUserData] = useState({});
  const [disableInvestments, setDisableInvestments] = useState(false);
  const [disableWorkers, setDisableWorkers] = useState(false);
  const [canClose, setCanClose] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      const { roles, workerPermissions } = data;
      const FaultsService = new faultGroupService(investmentId, null);

      const InvestmentsService = new investmentsService();
      InvestmentsService.getOne(investmentId)
        .then((investmentData) => setInvestment(investmentData));

      FaultsService.getOne(faultGroupId).then((faultData) => {
        if (!faultData.premisesId) {
        // eslint-disable-next-line no-param-reassign
          faultData = {
            ...faultData,
            premisesId: 'common',
            reporters: undefined,
          };
        }

        const tempData = {
          ...faultData,
          responsibleWorkers: faultData.responsibleWorkers ? faultData.responsibleWorkers : [],
          addDate: faultData.addDate ? moment(faultData.addDate) : null,
          fixDate: faultData.fixDate ? moment(faultData.fixDate) : null,
        };
        form.setFieldsValue(tempData);
        setFault(faultData);
        FaultsService.checkIfNameIsUsed(faultData.name, faultGroupId).then((checkResponse) => {
          if (checkResponse.status === 204) {
            form.setFields([
              {
                name: 'name',
                errors: [],
              },
            ]);
          }
        }).catch(() => {
          form.setFields([
            {
              name: 'name',
              errors: ['Taki numer występuje już w innej usterce.'],
            },
          ]);
        });
      });

      FaultsService.canCloseFaultGroup(faultGroupId).then((canCloseResponse) => {
        setCanClose(canCloseResponse.canCloseFaultGroup);
      });

      setLoading(false);

      if (!roles.includes('ROLE_WORKER')) {
        return;
      }

      setDisableInvestments(!workerPermissions.includes('INVESTMENT_FULL'));
      setDisableWorkers(!workerPermissions.includes('WORKERS_FULL'));
    });
  }, [faultGroupId, investmentId, form]);

  const onFieldsChange = () => {
    const status = form.getFieldValue('status');
    if (status) {
      if (!canClose && status === 'DONE') {
        form.setFields([
          {
            name: 'status',
            errors: ['Aby zamknąć zgłoszenie, zakończ wszystkie usterki.'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'status',
            errors: [],
          },
        ]);
      }
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const testName = form.getFieldError('name');
        if (testName.length > 0) {
          message.error('Taki numer występuje już w innej usterce.');
          return;
        }
        if (values?.addDate) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            addDate: values.addDate.format('YYYY-MM-DD'),
          };
        }
        if (values?.fixDate) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            fixDate: values.fixDate.format('YYYY-MM-DD'),
          };
        }
        if (values?.doneDate) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            doneDate: values.doneDate.format('YYYY-MM-DD'),
          };
        }
        if (values?.premisesId === 'common') {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            premisesId: null,
            premisesType: 'ADMINISTRATION',
          };
        }
        if (!values?.responsibleWorkers) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            responsibleWorkers: null,
          };
        }
        const FaultsService = new faultGroupService(values.investmentId, null);
        FaultsService.update(faultGroupId, values).then((response) => {
          if (response.id) {
            history.push(`/faultGroup/${response.investmentId}/faultGroup/${response.id}/show`);
          }
        }).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInvestmentChange = (selectedId) => {
    const formValues = form.getFieldsValue();
    if (!selectedId) {
      setInvestment({});
      form.setFieldsValue({
        premisesId: null, reporters: undefined, premisesType: null, clients: undefined,
      });
    } else {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getOne(selectedId).then((investmentData) => setInvestment(investmentData));
      let defaultPremise = null;
      if (formValues.premisesType === 'ADMINISTRATION') {
        defaultPremise = 'common';
      }
      form.setFieldsValue({ premisesId: defaultPremise, reporters: undefined });
    }
  };

  const onTypeChange = () => {
    const formValues = form.getFieldsValue();
    if (formValues.investmentId) {
      onInvestmentChange(formValues.investmentId);
    }
  };

  const handleOk = () => {
    const FaultsService = new faultGroupService(investmentId, null);
    FaultsService.delete(faultGroupId).then(() => history.push('/faultGroup')).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const HeaderTitle = () => {
    if (!fault.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{fault.name}</span>
      </span>
    );
  };

  const handleBack = () => {
    const { location } = history;
    if (location.state && location.state === 'createdFromInvestment') {
      return history.push(`/investments/${fault.investmentId}/premises/${fault.premisesId}/edit`);
    }

    return history.push('/faultGroup');
  };

  const getPremisesService = () => {
    const formValues = form.getFieldsValue();
    if (!formValues.investmentId) {
      return null;
    }
    return new PremisesServiceS(formValues.investmentId);
  };

  const getPremisesFilters = () => {
    const formValues = form.getFieldsValue();
    if (!formValues.premisesType) {
      return {};
    }
    return {
      type: formValues.premisesType,
    };
  };

  const getUserOptionLabel = (e) => {
    const pesel = e.identity && e.identity.pesel ? `(${e.identity.pesel})` : '';
    return `${e.firstName} ${e.lastName} ${pesel}`;
  };

  const verifyName = () => {
    const name = form.getFieldValue('name');
    const FaultsService = new faultGroupService(investmentId, null);
    FaultsService.checkIfNameIsUsed(name, faultGroupId).then((checkResponse) => {
      if (checkResponse.status === 204) {
        form.setFields([
          {
            name: 'name',
            errors: [],
          },
        ]);
      }
    }).catch(() => {
      form.setFields([
        {
          name: 'name',
          errors: ['Taki numer występuje już w innej usterce.'],
        },
      ]);
    });
  };

  const onNameChange = () => {
    verifyName();
  };

  const isClient = userData?.roles?.includes('ROLE_CLIENT') || userData?.roles?.includes('ROLE_CLIENT_TENANT') || false;
  const isDeveloper = userData?.roles?.includes('ROLE_DEVELOPER') || false;
  const getAdditionalFirstOption = () => (isClient ? null : <Option key="1" value="common">Część wspólna</Option>);

  return (
    <div className="form-create faults-edit">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => handleBack()}
          title={<HeaderTitle />}
          extra={
            isDeveloper && [
              <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
                <Icon name="delete" style={{ marginRight: '5px' }} />
                {' '}
                Usuń
              </Button>,
            ]
          }
        >
          <Form
            form={form}
            name="create"
            className="create-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            autoComplete="off"
          >
            <>
              <Card className="form-create-main-card">
                <Tabs defaultActiveKey="1" tabPosition="left">
                  <TabPane tab="Informacje" key="form">
                    <div className="create-form-wrapper">
                      <Row>
                        <Col xs={20} offset={2}>
                          <div className="form-section">
                            <h2>Lokal</h2>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="investmentId"
                                  rules={[{ required: true, message: 'Pole wymagane' }]}
                                >
                                  <SearchBox
                                    placeholder="Inwestycja"
                                    size="large"
                                    resource={new investmentsService()}
                                    filters={{
                                      types: 'SALE,RENT',
                                    }}
                                    resourceField="name"
                                    disabled={disableInvestments}
                                    onChange={(e) => onInvestmentChange(e)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="premisesType"
                                >
                                  <Select
                                    placeholder="Typ"
                                    allowClear
                                    size="large"
                                    disabled={disableInvestments}
                                    onChange={(e) => onTypeChange(e)}
                                  >
                                    {!isClient && <Option value="ADMINISTRATION">Administracja</Option>}
                                    {investment?.type === 'SALE' && (<Option value="SALE">Mieszkanie</Option>)}
                                    {investment?.type === 'RENT' && (<Option value="RENT">Mieszkanie</Option>)}
                                    <Option value="STORAGE">Komórka lokatorska</Option>
                                    <Option value="PARKING">Miejsce postojowe</Option>
                                    <Option value="SERVICE">Lokal usługowy</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="premisesId"
                                  rules={[{ required: true, message: 'Pole wymagane' }]}
                                >
                                  <SearchBox
                                    placeholder="Lokal"
                                    size="large"
                                    resource={getPremisesService()}
                                    filters={getPremisesFilters()}
                                    resourceField="name"
                                    disabled={disableInvestments}
                                    additionalFirstOption={getAdditionalFirstOption()}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          {!isClient && (
                          <div className="form-section">
                            <h2>Podstawowe</h2>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="name"
                                  onChange={(e) => onNameChange(e)}
                                >
                                  <Input size="large" placeholder="Numer" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="status"
                                  rules={[{ required: true, message: 'Pole wymagane' }]}
                                >
                                  <Select
                                    placeholder="Status"
                                    size="large"
                                  >
                                    <Option value="REPORTED">
                                      <span className="dot grey" />
                                      Zgłoszona
                                    </Option>
                                    <Option value="TO_FIX">
                                      <span className="dot red" />
                                      Do naprawy
                                    </Option>
                                    <Option value="REJECTED">
                                      <span className="dot blue" />
                                      Odrzucona
                                    </Option>
                                    <Option value="DONE">
                                      <span className="dot green" />
                                      Zakończona
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="addDate"
                                >
                                  <DatePicker size="large" placeholder="Data dodania" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="fixDate"
                                >
                                  <DatePicker size="large" placeholder="Data usunięcia" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          )}
                          {!isClient && (
                          <div className="form-section">
                            <h2>Szczegóły</h2>
                            <Row gutter={20}>
                              <Col xs={24}>
                                <Form.Item
                                  name="responsibleWorkers"
                                >
                                  <SearchBox
                                    placeholder="Osoba odpowiedzialna"
                                    size="large"
                                    resource={workersService}
                                    filters={{ role: 'ROLE_WORKER' }}
                                    resourceField="firstOrLastName"
                                    disabled={disableWorkers}
                                    optionValue={(e) => getUserOptionLabel(e)}
                                    mode="multiple"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tab="Usterki" key="faults">
                    <Faults
                      investmentId={investmentId}
                      faultGroupId={faultGroupId}
                      userData={userData}
                    />
                  </TabPane>
                  {!isClient && (
                  <TabPane tab="Tagi" key="tags" forceRender>
                    <Tags
                      investmentId={investmentId}
                      faultGroupId={faultGroupId}
                      form={form}
                    />
                  </TabPane>
                  )}
                </Tabs>
              </Card>
              <Divider />
              <Row className="form-actions" justify="space-between">
                <Col sm={4} className="form-actions-cancel-wrapper">
                  <Button className="button-secondary" size="large" onClick={handleBack}>
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className="form-actions-save-wrapper">
                  <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                      Zapisz
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Edit;
