import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '../../components/Icon';
import packageService from '../../services/packagesService';

const Package = ({ userData }) => {
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (userData.packageId) {
      packageService.getOne(userData.packageId).then((response) => {
        setPackageData(response);
        setLoading(false);
      });
    }

    if (userData.packageExpireDate) {
      const given = moment(userData.packageExpireDate, 'YYYY-MM-DD');
      const current = moment().startOf('day');

      const difference = moment.duration(given.diff(current)).asDays();
      if (difference <= 7) {
        setShowAlert(true);
      }
    }
  }, [userData.packageId, userData.packageExpireDate]);

  return (
    <>
      {!loading && <PackageButton packageData={packageData} showAlert={showAlert} />}
    </>
  );
};

const PackageButton = ({ packageData, showAlert }) => (
  <Link to="/myAccount/edit?package">
    <Button
      className="button-secondary header-package-button"
      style={{
        color: packageData.color ? packageData.color : 'inherit',
      }}
    >
      {showAlert && (
      <span style={{ color: '#8FB3D1', marginRight: '5px' }}>Opłać plan:</span>
      )}
      {packageData.name}
      {' '}
      <sup>{packageData.type === 'RENT' ? 'N' : 'S'}</sup>
      <Icon name="arrow-simply-right" />
    </Button>
  </Link>
);
export default Package;
