import React, { useState, useEffect } from 'react';
import {
  Row, Col, message, Tooltip, Menu, Table, Typography,
} from 'antd';
import { useHistory, Link } from 'react-router-dom';

import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import Price from '../../../components/Price';
import faultsStatusMap from '../../../helpers/faultsStatusMap';
import faultsService from '../../../services/faultsService';

const { Text } = Typography;

const Faults = ({ investmentId, faultGroupId, userData }) => {
  const [faults, setFaults] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const FaultsService = new faultsService(
      investmentId, faultGroupId,
    );
    FaultsService.getList().then((data) => {
      setFaults(data);
    });
  }, [investmentId, faultGroupId]);

  const refresh = () => {
    const FaultsService = new faultsService(
      investmentId, faultGroupId,
    );
    FaultsService.getList().then((data) => {
      setFaults(data);
    });
  };

  const Dot = (props) => {
    const { status } = props;
    let color = 'blue';
    if (status === 'DONE') {
      color = 'green';
    }

    if (status === 'TO_FIX') {
      color = 'red';
    }

    return (
      <Tooltip title={faultsStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'fixDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
  ];

  if (!userData.roles.includes('ROLE_CLIENT')) {
    columns.push({
      title: 'Kwota',
      dataIndex: 'amount',
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    });
  }

  const deleteAction = (entityId) => {
    const FaultsService = new faultsService(
      investmentId, faultGroupId,
    );
    FaultsService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const addNewAction = () => {
    history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/create`);
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const showAction = (
      <Menu.Item key="1" icon={<Icon name="eye-visible" style={{ marginRight: '5px' }} />}>
        <Link
          to={{
            pathname: `/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}/show`,
            state: { redirectFrom: 'FaultGroupEdit' },
          }}
          className="premises-custom-create-link"
        >
          Zobacz
        </Link>
      </Menu.Item>
    );
    tempArray.push(showAction);
    const editAction = (
      <Menu.Item key="3" icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        <Link
          to={{
            pathname: `/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}/edit`,
            state: { redirectFrom: 'FaultGroupEdit' },
          }}
          className="premises-custom-create-link"
        >
          Edytuj
        </Link>
      </Menu.Item>
    );
    tempArray.push(editAction);
    return tempArray;
  };

  const Summary = () => {
    if (faults.length === 0) {
      return '';
    }

    const total = faults.reduce((a, b) => a + (b.amount || 0), 0);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <Text style={{ color: '#C0D5E4' }}>Suma</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text style={{ fontWeight: 'bold' }}><Price value={total} showEmpty /></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const isDeveloper = userData.roles.includes('ROLE_DEVELOPER') || false;

  return (
    <div className="attachments create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            icon="flat-changes"
            data={faults}
            columns={columns}
            title="Lista usterek"
            customMenuActions={customMenuActions}
            hideEdit
            deleteAction={deleteAction}
            addAction={addNewAction}
            summary={<Summary />}
            hideDelete={!isDeveloper}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Faults;
