import React, {
  useEffect, useState, useRef,
} from 'react';
import {
  Col, Form, Tooltip, Tag,
} from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import faultGroupService from '../../services/faultGroupService';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import faultGroupTagsService from '../../services/faultGroupTagsService';
import faultGroupStatusMap from '../../helpers/faultGroupStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Spin from '../../components/Spin';
import ListExpanded from './ListExpanded';

const { Select, DatePicker, PriceInput } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  return (
    <Tooltip title={faultGroupStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = (props) => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [serviceType, setServiceType] = useState('');
  const [investments, setInvestments] = useState([]);
  const [userData, setUserData] = useState({});
  const [datagridFilters, setDatagridFilters] = useState({});
  const datagridRef = useRef();
  const history = useHistory();
  const {
    customDataService,
    isCustomRendered = false,
    redirectState = { redirectFrom: 'FaultGroupList' },
  } = props;

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      let type = '/user/faultGroup';
      let investmentFilterType = 'SALE';
      const { roles } = data;

      if (roles.includes('ROLE_DEVELOPER') || roles.includes('ROLE_TENANT') || roles.includes('ROLE_WORKER')) {
        type = '/vendor/faultGroup';
      }

      if (roles.includes('ROLE_TENANT')) {
        investmentFilterType = 'RENT';
      }

      setServiceType(type);

      const InvestmentsService = new investmentsService();
      InvestmentsService.getList({
        types: investmentFilterType,
        pageSize: 1000,
      }).then((investmentResponse) => {
        setInvestments(investmentResponse.content);
      });

      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: 'Zgłoszenie',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Typ',
      dataIndex: 'premisesType',
      visible: true,
      key: 'premisesType',
      sorter: false,
      render: (text, record) => premiseTypeMap(record.premisesType),
      exportRender: (text, record) => premiseTypeMap(record.premisesType),
    },
    {
      title: 'Numer',
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: false,
      render: (text, record) => (<PremiseRenderer record={record} />),
      exportRender: (text, record) => (<ExportPremiseRenderer record={record} />),
    },
    {
      title: 'Inwestycja',
      dataIndex: 'investmentName',
      visible: true,
      key: 'investmentName',
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => faultGroupStatusMap(record.status),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Usunięto',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
  ];

  const { roles } = userData;

  if (!loading && !roles.includes('ROLE_CLIENT') && !roles.includes('ROLE_CLIENT_TENANT')) {
    const workerFieldMapper = (worker) => {
      if (worker.nameDeveloper) {
        return worker.nameDeveloper;
      }

      return `${worker.firstName || ''} ${worker.lastName || ''}`;
    };

    columns.push(
      {
        title: 'Osoba odpowiedzialna',
        dataIndex: 'responsibleWorkers',
        visible: true,
        key: 'responsibleWorkers',
        sorter: false,
        render: (text, record) => {
          if (!record.responsibleWorkers) {
            return '';
          }
          const workersMap = record.responsibleWorkers.map(
            (worker) => workerFieldMapper(record.responsibleWorkersNames[worker]),
          );
          return workersMap.join(', ');
        },
      },
    );
  }
  if (!loading && !roles.includes('ROLE_CLIENT_TENANT') && !roles.includes('ROLE_CLIENT')) {
    columns.push({
      title: 'Koszt (brutto)',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    });
  }

  const PremiseRenderer = ({ record }) => {
    if (record.premisesId === null) {
      return 'Część wspólna';
    }
    const { workerPermissions } = userData;
    if (roles.includes('ROLE_WORKER') && (!workerPermissions || !workerPermissions.includes('INVESTMENT_FULL'))) {
      return record.premisesName;
    }

    const url = `investments/${record.investmentId}/premises/${record.premisesId}/show`;
    return <Link className="datagrid-link" to={url}>{record.premisesName}</Link>;
  };

  const ExportPremiseRenderer = ({ record }) => {
    if (record.premisesId === null) {
      return 'Część wspólna';
    }

    return record.premisesName;
  };

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="premisesName">
      <Form.Item
        name="premisesName"
      >
        <Input size="large" placeholder="Numer" />
      </Form.Item>
    </Col>,
  );
  customFilters.push(
    <Col xs={24} sm={12} key="investmentId">
      <Form.Item
        name="investmentId"
      >
        <Select
          placeholder="Inwestycja"
          allowClear
          size="large"
        >
          {investmentsOptions}
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="REPORTED">
            <span className="dot grey" />
            Zgłoszona
          </Option>
          <Option value="TO_FIX">
            <span className="dot red" />
            Do naprawy
          </Option>
          <Option value="REJECTED">
            <span className="dot blue" />
            Odrzucona
          </Option>
          <Option value="DONE">
            <span className="dot green" />
            Zakończona
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  if (!loading && !roles.includes('ROLE_CLIENT_TENANT') && !roles.includes('ROLE_CLIENT')) {
    customFilters.push(
      <Col xs={24} sm={12} key="addDateFrom">
        <Form.Item
          name="addDateFrom"
        >
          <DatePicker size="large" placeholder="Data dodania od" style={{ width: '100%' }} />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="addDateTo">
        <Form.Item
          name="addDateTo"
        >
          <DatePicker size="large" placeholder="Data dodania do" style={{ width: '100%' }} />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="fixDateFrom">
        <Form.Item
          name="fixDateFrom"
        >
          <DatePicker size="large" placeholder="Data usunięcia od" style={{ width: '100%' }} />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="fixDateTo">
        <Form.Item
          name="fixDateTo"
        >
          <DatePicker size="large" placeholder="Data usunięcia do" style={{ width: '100%' }} />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="amountFrom">
        <Form.Item
          name="amountFrom"
        >
          <PriceInput size="large" placeholder="Kwota od" />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="amountTo">
        <Form.Item
          name="amountTo"
        >
          <PriceInput size="large" placeholder="Kwota do" />
        </Form.Item>
      </Col>,
    );
  }

  let filters = {};
  if (query.get('status')) {
    filters = {
      status: query.get('status'),
    };
  }

  const customBaseUrl = (record) => `faultGroup/${record.investmentId}/faultGroup/${record.id}`;

  const TagFilters = () => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const FaultGroupTagsService = new faultGroupTagsService();
      FaultGroupTagsService.getAllFaultGroups().then((data) => {
        setTags(data);
      });

      if (!loading && query.has('tag') && datagridRef) {
        const filterTag = query.get('tag');

        query.delete('tag');
        history.replace({
          search: query.toString(),
        });
        const filter = {
          tags: filterTag,
        };
        datagridRef.current.handleFilterChange(filter, true);
      }
    }, []);

    const onTagClick = (tag) => {
      const filter = {
        tags: tag,
      };
      datagridRef.current.handleFilterChange(filter, true);
    };

    const getIsActiveTag = (tag) => {
      if (datagridFilters?.tags === tag) {
        return 'tag-active';
      }

      return '';
    };

    const renderTags = tags.map((tag) => (
      <Tag className={getIsActiveTag(tag)} onClick={() => onTagClick(tag)}>{`#${tag}`}</Tag>
    ));

    return renderTags;
  };

  const afterFilterChange = () => {
    setDatagridFilters(datagridRef.current.state.filters);
  };

  const canEdit = !roles?.includes('ROLE_CLIENT_TENANT') || false;
  const canDelete = roles?.includes('ROLE_DEVELOPER') || false;
  const dataProvider = customDataService || new faultGroupService(null, serviceType);

  return (
    <div className="faults-list">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <div className="datagrid-wrapper">
          {!isCustomRendered && (
            <>
              <h1>Usterki</h1>
              <div className="tags-filter">
                {(!roles.includes('ROLE_CLIENT_TENANT') && !roles.includes('ROLE_CLIENT')) && (
                  <TagFilters />
                )}
              </div>
            </>
          )}
          <Datagrid
            resource="faultGroup"
            dataProvider={dataProvider}
            columns={columns}
            customFilters={customFilters}
            filters={filters}
            expandedRowRenderer={(record) => (
              <ListExpanded
                record={record}
                roles={roles}
                redirectState={redirectState}
              />
            )}
            calculateBaseUrl={customBaseUrl}
            ref={datagridRef}
            afterFilterChange={afterFilterChange}
            canEdit={canEdit}
            hideCreate={!canEdit}
            hideEdit={!canEdit}
            hideDelete={!canDelete}
          />
        </div>
      )}
    </div>
  );
};

export default List;
