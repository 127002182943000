import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Form, Button, Checkbox, PageHeader, Row, Col, Card, Upload, Divider, message, Switch,
} from 'antd';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';

import Tabs from '../../components/Tabs';
import IdentityConfirmForm from '../../components/IdentityConfirmForm';

const { InputNumber, Select } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [userAvatar, setUserAvatar] = useState('');
  const [showCorrespondence, setShowCorrespondence] = useState(false);
  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }
  const history = useHistory();
  const [form] = Form.useForm();

  const showAlert = (resource) => {
    let expireDate = resource?.identity?.idExpireDate;
    if (!expireDate) {
      return false;
    }

    expireDate = new Date(expireDate);
    if (expireDate < new Date()) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    clientsService.getOne(id).then((data) => {
      if (data?.identity?.idExpireDate) {
        // eslint-disable-next-line no-param-reassign
        data = {
          ...data,
          identity: {
            ...data.identity,
            idExpireDate: moment(data.identity.idExpireDate),
          },
        };
      }
      setUserData(data);
      setUserAvatar(data.avatar);
      const defaultValues = data;
      if (data.correspondenceAddress) {
        setShowCorrespondence(true);
        defaultValues.differentAddress = true;
      }
      form.setFieldsValue(defaultValues);
      if (showAlert(defaultValues)) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      }
    });
  }, [form, id]);

  const onFinish = (values) => {
    let data = { ...values };
    if (values?.identity?.idExpireDate) {
      data = {
        ...values,
        identity: {
          ...values.identity,
          idExpireDate: values.identity.idExpireDate.format('YYYY-MM-DD'),
        },
      };
    }
    if (!data.differentAddress) {
      data.correspondenceAddress = null;
    }
    clientsService.update(id, data).then((response) => {
      if (response.id) {
        history.push(`/clientsTenants/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
      if (err?.response?.status === 409) {
        form.setFields([
          {
            name: 'username',
            errors: ['Podany login juz istnieje'],
          },
        ]);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadAvatar = (data) => {
    const formData = new FormData();
    formData.append('avatar', data.file);
    clientsService.uploadAvatar(id, formData).then((response) => {
      setUserAvatar(response.avatar);
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const sendPasswordAction = () => {
    const password = form.getFieldValue('password');
    const confirm = form.getFieldValue('confirm');
    if (password !== confirm) {
      return;
    }

    if (password.length < 8) {
      return;
    }
    clientsService.sendPassword(id, { password }).then(() => {
      message.success('Hasło zostało wysłane');
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleOk = () => {
    clientsService.delete(id).then(() => history.push('/clients')).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleCancel = () => {
    history.push('/clients');
  };

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  const IdentityTab = () => {
    if (showAlert(userData)) {
      return (
        <span>
          <Icon name="important" />
          Potwierdzenie tożsamości
        </span>
      );
    }

    return (
      <span>
        Potwierdzenie tożsamości
      </span>
    );
  };

  const onFieldsChange = () => {
    let expireDate = form.getFieldValue(['identity', 'idExpireDate']);
    if (expireDate) {
      expireDate = new Date(expireDate);
      if (expireDate < new Date()) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: [],
          },
        ]);
      }
    }
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/clientsTenants')}
        title={<HeaderTitle />}
        extra={[
          <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
            <Icon name="delete" style={{ marginRight: '5px' }} />
            {' '}
            Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Informacje" key="form">
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="form-section">
                          <h2>Rodzaj konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name="isCompany"
                                label="Konto firmowe"
                                valuePropName="checked"
                              >
                                <Switch size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Miniaturka</h2>
                          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                            <Upload.Dragger
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              customRequest={uploadAvatar}
                              action="avatar"
                              beforeUpload={beforeUpload}
                            >
                              <div className="upload-avatar-wrapper">
                                {userAvatar && (
                                  <img src={`${baseUrl}${userAvatar}`} alt="avatar" />
                                )}
                                <Button size="large" className="change-button" type="primary">
                                  Zmień
                                  {' '}
                                  <Icon name="edit" />
                                </Button>
                              </div>
                            </Upload.Dragger>
                          </Form.Item>
                          <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                        </div>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                        >
                          {({ getFieldValue }) => getFieldValue('isCompany') && (
                          <>
                            <div className="form-section">
                              <h2>Dane firmowe</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Nazwa firmy" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="nip"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input.MaskedInput mask="111-111-11-11" size="large" placeholder="NIP" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </>
                          )}
                        </Form.Item>
                        <div className="form-section">
                          <h2>Dane najemcy</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="firstName"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Imię" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="secondName"
                              >
                                <Input size="large" placeholder="Drugie imię (opcjonalne)" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Nazwisko" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Adres zamieszkania</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Ulica" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Numer" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'local']}
                              >
                                <Input size="large" placeholder="Mieszkanie" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Miejscowość" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                              <Checkbox
                                onChange={correspondenceShow}
                              >
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size="large" placeholder="Ulica" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size="large" placeholder="Numer" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input size="large" placeholder="Mieszkanie" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input size="large" placeholder="Miejscowość" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className="form-section">
                          <h2>Dane kontaktowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="email"
                                rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
                              >
                                <Input size="large" placeholder="E-Mail" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="mobile"
                              >
                                <InputNumber size="large" placeholder="Telefon" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item name="enabled" valuePropName="checked" noStyle>
                            <Checkbox>Aktywny</Checkbox>
                          </Form.Item>
                        </div>
                        <div className="form-section">
                          <h2>Dane dotyczące majątku</h2>
                          <Form.Item
                            name="wealthSource"
                          >
                            <Select
                              placeholder="Bank"
                              size="large"
                            >
                              <Option value="Nie dotyczy">Nie dotyczy</Option>
                              <Option value="Wspólnota majątkowa">Wspólnota majątkowa</Option>
                              <Option value="Rozdzielność majątkowa">Rozdzielność majątkowa</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="form-section">
                          <h2>Jak się o nas dowiedziałeś/-aś?</h2>
                          <Form.Item
                            name="clientSource"
                          >
                            <Select
                              placeholder="Źródło klienta"
                              size="large"
                            >
                              <Option value="Wspólnota majątkowa">Wyszukiwarka internetowa</Option>
                              <Option value="Ulotka informacyjna">Ulotka informacyjna</Option>
                              <Option value="Z polecenia">Z polecenia</Option>
                              <Option value="Media społecznościowe">Media społecznościowe</Option>
                              <Option value="Reklama prasowa, billboardy">Reklama prasowa, billboardy</Option>
                              <Option value="Inne">Inne</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, curr) => prev.clientSource !== curr.clientSource}
                          >
                            {({ getFieldValue }) => getFieldValue('clientSource') === 'Inne' && (
                            <>
                              <Form.Item
                                name="clientSourceDescription"
                              >
                                <Input size="large" placeholder="Inne źródło" />
                              </Form.Item>
                            </>
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Card className="create-form-password-card">
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className="form-section">
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name="username"
                              rules={[{ required: true, message: 'Proszę uzupełnić login' }]}
                            >
                              <Input size="large" placeholder="Login" />
                            </Form.Item>
                            <Form.Item
                              name="password"
                              rules={[{ message: 'Pole wymagane' },
                                { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
                            >
                              <Input
                                type="password"
                                size="large"
                                placeholder="Hasło"
                              />
                            </Form.Item>
                            <Form.Item
                              name="confirm"
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject('Hasło nieprawidłowe');
                                  },
                                }),
                              ]}
                            >
                              <Input.Password size="large" placeholder="Powtórz hasło" />
                            </Form.Item>
                            <Form.Item>
                              <Button className="create-form-button-send-password" size="large" onClick={() => sendPasswordAction()}>
                                Wyślij login i hasło
                                {' '}
                                <Icon name="access" />
                              </Button>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className="send-password-image-wrapper">
                            <img src={image} alt="send password icon" />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>
                <TabPane key="identity" tab={<IdentityTab />} forceRender>
                  <IdentityConfirmForm />
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
