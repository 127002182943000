import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultsService extends BaseService {
  constructor(investmentId, faultGroupId, type = null, userId = null) {
    super();
    const roles = localStorage.getItem('roles');

    if (userId) {
      this.url = `/api/vendor/user/${userId}/fault`;
    } else if (type) {
      this.url = `/api${type}`;
    } else {
      this.url = `/api/vendor/investment/${investmentId}/faultGroup/${faultGroupId}/fault`;

      if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
        this.url = `/api/user/faultGroup/${faultGroupId}/fault`;
      }
    }
  }

  async getAssignedFaults(premiseId) {
    return httpService.get(`${this.url}/premises/${premiseId}`, null, true);
  }

  async updateConfirmation(faultId, body) {
    return httpService.patch(`${this.url}/${faultId}`, body, true);
  }
}

export default FaultsService;
