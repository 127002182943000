import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Form, Button, Checkbox, PageHeader, Row, Col,
  Card, Upload, Divider, message, Switch, Menu, Dropdown,
} from 'antd';
import userService from '../../services/userService';
import paymentsCredentialsService from '../../services/paymentsCredentialsService';
import packagesService from '../../services/packagesService';
import PackagePricesService from '../../services/packagePricesService';
import rodoService from '../../services/rodoService';
import Icon from '../../components/Icon';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import tpayLogo from '../../static/images/tpay-logo.png';

import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';

import Tabs from '../../components/Tabs';
import Spin from '../../components/Spin';

const {
  InputNumber, Select, Password, TextArea,
} = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const useQuery = () => new URLSearchParams(useLocation().search);

const Edit = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [userAvatar, setUserAvatar] = useState('');
  const [userData, setUserData] = useState({});
  const [rodoData, setRodoData] = useState(null);
  const query = useQuery();

  const [packageData, setPackageData] = useState({});
  const [packagePrice, setPackagePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const defaultActiveTab = query.get('package') != null ? 'packages' : 'form';

  const history = useHistory();
  const [form] = Form.useForm();
  const [formTpay] = Form.useForm();

  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }
  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      setUserAvatar(data.avatar);
      const defaultValues = data;

      if (data.correspondenceAddress) {
        setShowCorrespondence(true);
        defaultValues.differentAddress = true;
      }
      form.setFieldsValue(defaultValues);
      rodoService.getList().then((rodoResponse) => {
        setRodoData(rodoResponse);
        const formValues = form.getFieldsValue();
        form.setFieldsValue({
          ...formValues,
          rodo: rodoResponse.content,
        });
      });
      if (data.packageId) {
        const PackagePriceService = new PackagePricesService(data.packageId);
        PackagePriceService.getPackagePrices().then((pricesResponse) => {
          const sortedResponse = pricesResponse.sort(
            (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
          );
          setPackagePrice(sortedResponse);
        });
        packagesService.getOne(data.packageId).then((packageResponse) => {
          setPackageData(packageResponse);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });

    paymentsCredentialsService.getList().then((paymentCredentialsResponse) => {
      formTpay.setFieldsValue(paymentCredentialsResponse);
    });
  }, [form, formTpay]);

  const onFinish = (values) => {
    if (values.rodo) {
      if (!rodoData) {
        rodoService.create({ content: values.rodo });
      } else {
        rodoService.update(rodoData.id, { content: values.rodo });
      }
    }
    const data = { ...values, identity: userData.identity };
    if (!data.differentAddress) {
      data.correspondenceAddress = null;
    }
    userService.update(data).then((response) => {
      if (response.id) {
        if (userData.roles.includes('ROLE_ADMIN')) {
          return history.push('/dashboard');
        }
      }
      return history.push('/myAccount');
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onTpayFinish = (values) => {
    if (!values.id) {
      paymentsCredentialsService.create(values).then((paymentCredentialsResponse) => {
        formTpay.setFieldsValue(paymentCredentialsResponse);
        message.success('Konfiguracja została zapisana');
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
    } else {
      paymentsCredentialsService.update(values.id, values).then(() => {
        formTpay.setFieldsValue('Konfiguracja została zapisana');
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadAvatar = (data) => {
    const formData = new FormData();
    formData.append('avatar', data.file);
    userService.uploadAvatar(formData).then((response) => {
      setUserAvatar(response.avatar);
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const handleCancel = () => {
    history.push('/myAccount');
  };

  const AdminForm = () => (
    <div className="create-form-wrapper">
      <Card className="create-form-password-card">
        <Row gutter={20}>
          <Col xs={24} sm={10} offset={2}>
            <div className="form-section">
              <h2>Dane do logowania</h2>
              <Form.Item
                name="password"
                rules={[{ message: 'Pole wymagane' },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="Hasło"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    message: 'Potwierdź hasło',
                  },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Hasło nieprawidłowe');
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Powtórz hasło" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={0} sm={10}>
            <div className="send-password-image-wrapper">
              <img src={image} alt="send password icon" />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );

  const DeveloperForm = () => (
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Miniaturka</h2>
            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadAvatar}
                action="avatar"
                beforeUpload={beforeUpload}
              >
                <div className="upload-avatar-wrapper">
                  {userAvatar && (
                  <img src={`${baseUrl}${userAvatar}`} alt="avatar" />
                  )}
                  <Button size="large" className="change-button" type="primary">
                    Zmień
                    {' '}
                    <Icon name="edit" />
                  </Button>
                </div>
              </Upload.Dragger>
            </Form.Item>
            <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
          </div>
          <div className="form-section">
            <h2>Dane podstawowe</h2>
            <Row gutter={20}>
              <Col xs={24}>
                <Form.Item
                  name="nameDeveloper"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Nazwa dewelopera" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <h2>Dane dewelopera</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Nazwa firmy" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="nip"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input.MaskedInput mask="111-111-11-11" size="large" placeholder="NIP" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <h2>Adres siedziby</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'street']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Ulica" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'number']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Numer" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'local']}
                >
                  <Input size="large" placeholder="Mieszkanie" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'city']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Miejscowość" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'zip']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'country']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <CountrySelect />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                <Checkbox
                  onChange={correspondenceShow}
                >
                  Inny adres do korespondencji
                </Checkbox>
              </Form.Item>
            </Form.Item>
            {showCorrespondence && (
            <>
              <h2>Adres do korespondencji</h2>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'street']}
                  >
                    <Input size="large" placeholder="Ulica" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'number']}
                  >
                    <Input size="large" placeholder="Numer" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'local']}
                  >
                    <Input size="large" placeholder="Mieszkanie" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'city']}
                  >
                    <Input size="large" placeholder="Miejscowość" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'zip']}
                  >
                    <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'country']}
                  >
                    <CountrySelect />
                  </Form.Item>
                </Col>
              </Row>
            </>
            )}
          </div>
          <div className="form-section">
            <h2>Dane kontaktowe</h2>
            <Form.Item
              name="email"
              rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
            >
              <Input size="large" placeholder="E-Mail" />
            </Form.Item>
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: 'Pole wymagane' }]}
            >
              <InputNumber size="large" placeholder="Telefon" />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Card className="create-form-password-card">
        <Row gutter={20}>
          <Col xs={24} sm={10} offset={2}>
            <div className="form-section">
              <h2>Dane do logowania</h2>
              <Form.Item
                name="password"
                rules={[{ message: 'Pole wymagane' },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="Hasło"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    message: 'Potwierdź hasło',
                  },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Hasło nieprawidłowe');
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Powtórz hasło" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={0} sm={10}>
            <div className="send-password-image-wrapper">
              <img src={image} alt="send password icon" />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );

  const TenantForm = () => (
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Rodzaj konta</h2>
            <Row gutter={20}>
              <Col xs={24}>
                <Form.Item
                  name="isCompany"
                  label="Konto firmowe"
                  valuePropName="checked"
                >
                  <Switch size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <h2>Miniaturka</h2>
            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadAvatar}
                action="avatar"
                beforeUpload={beforeUpload}
              >
                <div className="upload-avatar-wrapper">
                  {userAvatar && (
                  <img src={`${baseUrl}${userAvatar}`} alt="avatar" />
                  )}
                  <Button size="large" className="change-button" type="primary">
                    Zmień
                    {' '}
                    <Icon name="edit" />
                  </Button>
                </div>
              </Upload.Dragger>
            </Form.Item>
            <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
          </div>
          <div className="form-section">
            <h2>Dane zarządcy najmem</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Imię" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="secondName"
                >
                  <Input size="large" placeholder="Drugie imię (opcjonalne)" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Nazwisko" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
          >
            {({ getFieldValue }) => getFieldValue('isCompany') && (
            <>
              <div className="form-section">
                <h2>Dane firmowe</h2>
                <Row gutter={20}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="nameDeveloper"
                      rules={[{ required: true, message: 'Pole wymagane' }]}
                    >
                      <Input size="large" placeholder="Nazwa firmy" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="nip"
                      rules={[{ required: true, message: 'Pole wymagane' }]}
                    >
                      <Input.MaskedInput mask="111-111-11-11" size="large" placeholder="NIP" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
            )}
          </Form.Item>
          <div className="form-section">
            <h2>Adres siedziby</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'street']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Ulica" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'number']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Numer" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'local']}
                >
                  <Input size="large" placeholder="Mieszkanie" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'city']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Miejscowość" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'zip']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'country']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <CountrySelect />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                <Checkbox
                  onChange={correspondenceShow}
                >
                  Inny adres do korespondencji
                </Checkbox>
              </Form.Item>
            </Form.Item>
            {showCorrespondence && (
            <>
              <h2>Adres do korespondencji</h2>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'street']}
                  >
                    <Input size="large" placeholder="Ulica" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'number']}
                  >
                    <Input size="large" placeholder="Numer" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'local']}
                  >
                    <Input size="large" placeholder="Mieszkanie" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'city']}
                  >
                    <Input size="large" placeholder="Miejscowość" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'zip']}
                  >
                    <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'country']}
                  >
                    <CountrySelect />
                  </Form.Item>
                </Col>
              </Row>
            </>
            )}
          </div>
          <div className="form-section">
            <h2>Dane kontaktowe</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="email"
                  rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
                >
                  <Input size="large" placeholder="E-Mail" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="mobile"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <InputNumber size="large" placeholder="Telefon" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <h2>Dane dotyczące majątku</h2>
            <Form.Item
              name="wealthSource"
            >
              <Select
                placeholder="Bank"
                size="large"
              >
                <Option value="Nie dotyczy">Nie dotyczy</Option>
                <Option value="Wspólnota majątkowa">Wspólnota majątkowa</Option>
                <Option value="Rozdzielność majątkowa">Rozdzielność majątkowa</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Card className="create-form-password-card">
        <Row>
          <Col xs={24} sm={10} offset={2}>
            <div className="form-section">
              <h2>Dane do logowania</h2>
              <Form.Item
                name="password"
                rules={[{ message: 'Pole wymagane' },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="Hasło"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    message: 'Potwierdź hasło',
                  },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Hasło nieprawidłowe');
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Powtórz hasło" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={0} sm={10}>
            <div className="send-password-image-wrapper">
              <img src={image} alt="send password icon" />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );

  const WorkerForm = () => (
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Miniaturka</h2>
            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadAvatar}
                action="avatar"
                beforeUpload={beforeUpload}
              >
                <div className="upload-avatar-wrapper">
                  {userAvatar && (
                  <img src={`${baseUrl}${userAvatar}`} alt="avatar" />
                  )}
                  <Button size="large" className="change-button" type="primary">
                    Zmień
                    {' '}
                    <Icon name="edit" />
                  </Button>
                </div>
              </Upload.Dragger>
            </Form.Item>
            <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
          </div>
          <div className="form-section">
            <h2>Dane pracownika</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Imię" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="secondName"
                >
                  <Input size="large" placeholder="Drugie imię (opcjonalne)" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Nazwisko" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <h2>Adres zamieszkania</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'street']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Ulica" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'number']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Numer" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['address', 'local']}
                >
                  <Input size="large" placeholder="Mieszkanie" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'city']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input size="large" placeholder="Miejscowość" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'zip']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['address', 'country']}
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <CountrySelect />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                <Checkbox
                  onChange={correspondenceShow}
                >
                  Inny adres do korespondencji
                </Checkbox>
              </Form.Item>
            </Form.Item>
            {showCorrespondence && (
            <>
              <h2>Adres do korespondencji</h2>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'street']}
                  >
                    <Input size="large" placeholder="Ulica" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'number']}
                  >
                    <Input size="large" placeholder="Numer" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['correspondenceAddress', 'local']}
                  >
                    <Input size="large" placeholder="Mieszkanie" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'city']}
                  >
                    <Input size="large" placeholder="Miejscowość" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'zip']}
                  >
                    <Input.MaskedInput mask="11-111" size="large" placeholder="Kod pocztowy" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['correspondenceAddress', 'country']}
                  >
                    <CountrySelect />
                  </Form.Item>
                </Col>
              </Row>
            </>
            )}
          </div>
          <div className="form-section">
            <h2>Dane kontaktowe</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="email"
                  rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
                >
                  <Input size="large" placeholder="E-Mail" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="mobile"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <InputNumber size="large" placeholder="Telefon" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Card className="create-form-password-card">
        <Row gutter={20}>
          <Col xs={24} sm={10} offset={2}>
            <div className="form-section">
              <h2>Dane do logowania</h2>
              <Form.Item
                name="password"
                rules={[{ message: 'Pole wymagane' },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' }]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="Hasło"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    message: 'Potwierdź hasło',
                  },
                  { min: 8, message: 'Hasło musi posiadać conajmniej 8 znaków.' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Hasło nieprawidłowe');
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Powtórz hasło" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={0} sm={10}>
            <div className="send-password-image-wrapper">
              <img src={image} alt="send password icon" />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );

  const onBackAction = () => {
    if (userData?.roles.includes('ROLE_ADMIN')) {
      return window.history.back();
    }
    return history.push('/myAccount');
  };

  const TPayForm = () => (
    <div className="permissions create-form-wrapper">
      <Row>
        <Col xs={23}>
          <div className="tpay-settings">
            <div className="form-section">
              <h2>Konto Tpay</h2>
              <Form form={formTpay} name="create" className="create-form" onFinish={onTpayFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                <>
                  <Row gutter={20}>
                    <Col xs={24} sm={12}>
                      <Form.Item name="id" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name="tpayId" rules={[{ required: true, message: 'Pole wymagane' }]}>
                        <Input size="large" placeholder="Identyfikator Tpay" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="apiCode" rules={[{ required: true, message: 'Pole wymagane' }]}>
                        <Password size="large" placeholder="API Code" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="apiSecret" rules={[{ required: true, message: 'Pole wymagane' }]}>
                        <Password size="large" placeholder="API Secret" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="apiPassword" rules={[{ required: true, message: 'Pole wymagane' }]}>
                        <Password size="large" placeholder="API Password" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={12} className="button-wrapper">
                      <Button className="tpay-save-button" type="primary" size="large" onClick={() => formTpay.submit()}>
                        Zapisz dane
                        {' '}
                        <Icon name="access" style={{ marginLeft: '10px' }} />
                      </Button>
                    </Col>
                    <Col xs={0} sm={12} className="image-wrapper">
                      <img src={tpayLogo} alt="Tpay" />
                    </Col>
                  </Row>
                </>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const PackageForm = () => {
    const packageType = packageData.type === 'RENT' ? 'N' : 'S';

    const createPayment = (days) => {
      const PackagePriceService = new PackagePricesService(userData.packageId);
      const body = {
        days,
      };
      PackagePriceService.createPayment(body).then((paymentResponse) => {
        if (paymentResponse.id) {
          history.push(`/payments/null/premises/null/agreement/null/payment/${paymentResponse.id}/show`);
        }
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
    };
    const menu = (
      <Menu>
        {packagePrice.map((price) => (
          <Menu.Item onClick={() => createPayment(price.days)}>
            {`${price.days} dni - ${price.amount} zł`}
          </Menu.Item>
        ))}
      </Menu>
    );

    const PackageLimitRenderer = ({ value }) => {
      if (value !== -1) {
        return `max ${value}`;
      }

      return '∞';
    };

    return (
      <div className="create-form-wrapper">
        <div className="account-edit-packages" style={{ backgroundColor: packageData.color }}>
          <div className="account-edit-packages-header">
            <Row>
              <Col xs={24} sm={12} className="account-edit-packages-header-name">
                {packageData.name}
                {' '}
                <sup className="single-package-header-sup">{packageType}</sup>

              </Col>
              <Col xs={24} sm={12} className="account-edit-packages-header-price">
                <div className="account-edit-packages-header-price-value">
                  {`${packageData.price} zł`}
                </div>
                <div className="account-edit-packages-header-price-addnotation">Kwota netto + 23% VAT</div>
              </Col>
            </Row>
          </div>

          <div className="account-edit-packages-details">
            <Row>
              <Col xs={24} sm={12}>
                <div className="account-edit-packages-details-limits">
                  <div className="account-edit-packages-details-limits-single-investments">
                    <Row>
                      <Col className="account-edit-packages-details-limits-single-investments-label" xs={16}>Ważny do</Col>
                      <Col className="account-edit-packages-details-limits-single-investments-value" xs={8}>{userData.packageExpireDate}</Col>
                    </Row>
                  </div>
                  {packageData.type !== 'RENT' ? (
                    <>
                      <div className="account-edit-packages-details-limits-single-investments">
                        <Row>
                          <Col className="account-edit-packages-details-limits-single-investments-label" xs={16}>Inwestycje</Col>
                          <Col className="account-edit-packages-details-limits-single-investments-value" xs={8}><PackageLimitRenderer value={packageData.limits.investments} /></Col>
                        </Row>
                      </div>
                      <div className="account-edit-packages-details-limits-single">
                        <Row>
                          <Col className="account-edit-packages-details-limits-single-label" xs={16}>Lokale mieszkalne</Col>
                          <Col className="account-edit-packages-details-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.premises} /></Col>
                        </Row>
                      </div>
                      <div className="account-edit-packages-details-limits-single">
                        <Row>
                          <Col className="account-edit-packages-details-limits-single-label" xs={16}>Lokale usługowe</Col>
                          <Col className="account-edit-packages-details-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.premises} /></Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <div className="account-edit-packages-details-limits-single">
                      <Row>
                        <Col className="account-edit-packages-details-limits-single-label" xs={16}>Lokale mieszkalne lub usługowe</Col>
                        <Col className="account-edit-packages-details-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.premisesRent} /></Col>
                      </Row>
                    </div>
                  )}
                  <div className="account-edit-packages-details-limits-single">
                    <Row>
                      <Col className="account-edit-packages-details-limits-single-label" xs={16}>Miejsca postojowe</Col>
                      <Col className="account-edit-packages-details-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.parking} /></Col>
                    </Row>
                  </div>
                  <div className="account-edit-packages-details-limits-single">
                    <Row>
                      <Col className="account-edit-packages-details-limits-single-label" xs={16}>Komórki lokatorskie</Col>
                      <Col className="account-edit-packages-details-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.storage} /></Col>
                    </Row>
                  </div>
                  {packageData.type !== 'RENT' && (
                    <div className="account-edit-packages-details-limits-addnotation">
                      / na 1 inwestycje
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} style={{ alignSelf: 'flex-end' }}>
                <div className="account-edit-packages-details-actions">
                  <Link to="/changePackage">
                    <Button className="button-secondary">
                      Zmień plan
                    </Button>
                  </Link>
                  <Dropdown overlay={menu} placement="bottomCenter" arrow>
                    <Button type="danger">
                      Opłać pakiet
                      <Icon name="payments" style={{ marginLeft: '5px' }} />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const RodoForm = () => (
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Klauzula informacyjna RODO</h2>
            <Row gutter={20}>
              <Col xs={24}>
                <Form.Item
                  name="rodo"
                >
                  <TextArea rows={4} placeholder="Treść" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => onBackAction()}
        title="Edycja konta"
      >
        {loading ? (
          <div className="loading"><Spin /></div>
        ) : (
          <Form
            form={form}
            name="create"
            className="create-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <>
              <Card className="form-create-main-card">
                {userData.roles.includes('ROLE_ADMIN') && (
                  <Tabs defaultActiveKey="1" tabPosition="left">
                    <TabPane tab="Informacje" key="form">
                      <AdminForm />
                    </TabPane>
                    <TabPane tab="Konfiguracja płatności" key="permissions">
                      <TPayForm />
                    </TabPane>
                  </Tabs>
                )}
                {userData.roles.includes('ROLE_WORKER') && (
                <WorkerForm />
                )}
                {userData.roles.includes('ROLE_DEVELOPER') && (
                <Tabs defaultActiveKey={defaultActiveTab} tabPosition="left">
                  <TabPane tab="Informacje" key="form">
                    <DeveloperForm />
                  </TabPane>
                  <TabPane tab="Klauzula" key="rodo">
                    <RodoForm />
                  </TabPane>
                  <TabPane tab="Twój plan" key="packages">
                    <PackageForm />
                  </TabPane>
                </Tabs>
                )}
                {userData.roles.includes('ROLE_TENANT') && !userData.roles.includes('ROLE_DEVELOPER') && (
                <Tabs defaultActiveKey={defaultActiveTab} tabPosition="left" activeKey="packages">
                  <TabPane tab="Informacje" key="form">
                    <TenantForm />
                  </TabPane>
                  <TabPane tab="Klauzula" key="rodo">
                    <RodoForm />
                  </TabPane>
                  <TabPane tab="Konfiguracja płatności" key="permissions">
                    <TPayForm />
                  </TabPane>
                  <TabPane tab="Twój plan" key="packages">
                    <PackageForm />
                  </TabPane>
                </Tabs>
                )}
              </Card>
              <Divider />
              <Row className="form-actions" justify="space-between">
                <Col sm={4} className="form-actions-cancel-wrapper">
                  <Button className="button-secondary" size="large" onClick={handleCancel}>
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className="form-actions-save-wrapper">
                  <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                      Zapisz
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        )}
      </PageHeader>
    </div>
  );
};

export default Edit;
