import React from 'react';
import {
  Button, Form, Checkbox, Divider, Row, Col,
} from 'antd';
import Icon from '../Icon';

const FilterColumns = (props) => {
  const { columns, updateColumnsVisibility, hidePopover } = props;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    updateColumnsVisibility(values);
    hidePopover();
  };

  const onReset = () => {
    const resetValues = {};
    Object.keys(form.getFieldsValue()).forEach((key) => {
      resetValues[key] = true;
    });
    form.setFieldsValue(resetValues);
    onFinish(resetValues);
  };

  const columnsCheckboxes = columns.filter((column) => column.export !== false).map((column) => (
    <Form.Item name={column.key} key={column.key} style={{ marginBottom: 0 }} valuePropName="checked">
      <Checkbox>{column.title}</Checkbox>
    </Form.Item>
  ));

  const defaultValues = {};
  columns.forEach((column) => {
    defaultValues[column.key] = column.visible;
  });

  return (
    <div>
      <Form
        name="basic"
        initialValues={defaultValues}
        onFinish={onFinish}
        form={form}
      >
        {columnsCheckboxes}
        <Divider />
        <Row className="datagrid-cloumns-buttons" gutter={8}>
          <Col xs={8}>
            <Form.Item>
              <Button type="danger" className="datagrid-columns-buttons-refresh" onClick={onReset}>
                <Icon name="refresh" />
              </Button>
            </Form.Item>
          </Col>
          <Col xs={16}>
            <Form.Item>
              <Button type="primary" className="datagrid-columns-buttons-ok" htmlType="submit" style={{ width: '100%' }}>
                Ok
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterColumns;
