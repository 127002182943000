import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import investmentsService from '../../services/investmentsService';
import PremisesServiceS from '../../services/premisesService';
import workersService from '../../services/workersService';
import userService from '../../services/userService';
import { Icon, Spin, Input } from '../../components';

const {
  DatePicker, Select, SearchBox,
} = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const premisesId = query.get('premisesId') || null;
  const investmentId = query.get('investmentId') || null;
  const [investment, setInvestment] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      if (premisesId && investmentId) {
        const InvestmentsService = new investmentsService();
        InvestmentsService.getOne(investmentId)
          .then((investmentData) => setInvestment(investmentData));
      }

      setLoading(false);
    });
  }, [investmentId, premisesId]);

  const onFinish = (values) => {
    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        addDate: values.addDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.fixDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        fixDate: values.fixDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.premisesId === 'common') {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        premisesId: null,
      };
    }
    const FaultsService = new faultGroupService(values.investmentId, null);
    FaultsService.create(values).then((response) => {
      if (response.id) {
        let createdState = '';
        if (premisesId && investmentId) {
          createdState = 'createdFromInvestment';
        }
        history.push(`/faultGroup/${response.investmentId}/faultGroup/${response.id}/edit`, createdState);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInvestmentChange = (selectedId) => {
    const formValues = form.getFieldsValue();
    if (!selectedId) {
      setInvestment({});
      form.setFieldsValue({
        premisesId: null, reporters: undefined, premisesType: null, clients: undefined,
      });
    } else {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getOne(selectedId).then((investmentData) => setInvestment(investmentData));
      let defaultPremise = null;
      if (formValues.premisesType === 'ADMINISTRATION') {
        defaultPremise = 'common';
      }
      form.setFieldsValue({ premisesId: defaultPremise, reporters: undefined, clients: undefined });
    }
  };

  const onTypeChange = () => {
    const formValues = form.getFieldsValue();
    if (formValues.investmentId) {
      onInvestmentChange(formValues.investmentId);
    }
  };

  const handleBack = () => {
    if (premisesId && investmentId) {
      return history.push(`/investments/${investmentId}/premises/${premisesId}/edit`);
    }

    return history.push('/faultGroup');
  };

  const getPremisesService = () => {
    const formValues = form.getFieldsValue();
    if (!formValues.investmentId) {
      return null;
    }
    return new PremisesServiceS(formValues.investmentId);
  };

  const getPremisesFilters = () => {
    const formValues = form.getFieldsValue();
    if (!formValues.premisesType) {
      return {};
    }
    return {
      type: formValues.premisesType,
    };
  };

  const getUserOptionLabel = (e) => {
    const pesel = e.identity && e.identity.pesel ? `(${e.identity.pesel})` : '';
    return `${e.firstName} ${e.lastName} ${pesel}`;
  };
  const isClient = userData?.roles?.includes('ROLE_CLIENT') || userData?.roles?.includes('ROLE_CLIENT_TENANT') || false;
  const getAdditionalFirstOption = () => (isClient ? null : <Option key="1" value="common">Część wspólna</Option>);

  return (
    <div className="form-create">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => handleBack()}
          title="Dodaj usterke"
        >
          <Form
            form={form}
            name="create"
            className="create-form"
            initialValues={{
              investmentId, premisesId, addDate: moment(), status: 'REPORTED',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <>
              <Card className="form-create-main-card">
                <div className="create-form-wrapper">
                  <Row>
                    <Col xs={20} offset={2}>
                      <div className="form-section">
                        <h2>Lokal</h2>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="investmentId"
                              rules={[{ required: true, message: 'Pole wymagane' }]}
                            >
                              <SearchBox
                                placeholder="Inwestycja"
                                size="large"
                                resource={new investmentsService()}
                                filters={{
                                  types: 'SALE,RENT',
                                }}
                                resourceField="name"
                                onChange={(e) => onInvestmentChange(e)}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="premisesType"
                            >
                              <Select
                                placeholder="Typ"
                                allowClear
                                size="large"
                                onChange={(e) => onTypeChange(e)}
                              >
                                {!isClient && <Option value="ADMINISTRATION">Administracja</Option>}
                                {investment?.type === 'SALE' && (<Option value="SALE">Mieszkanie</Option>)}
                                {investment?.type === 'RENT' && (<Option value="RENT">Mieszkanie</Option>)}
                                <Option value="STORAGE">Komórka lokatorska</Option>
                                <Option value="PARKING">Miejsce postojowe</Option>
                                <Option value="SERVICE">Lokal usługowy</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="premisesId"
                              rules={[{ required: true, message: 'Pole wymagane' }]}
                            >
                              <SearchBox
                                placeholder="Lokal"
                                size="large"
                                resource={getPremisesService()}
                                filters={getPremisesFilters()}
                                resourceField="name"
                                additionalFirstOption={getAdditionalFirstOption()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      {!isClient && (
                      <div className="form-section">
                        <h2>Podstawowe</h2>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="status"
                              rules={[{ required: true, message: 'Pole wymagane' }]}
                            >
                              <Select
                                placeholder="Status"
                                size="large"
                              >
                                <Option value="REPORTED">
                                  <span className="dot grey" />
                                  Zgłoszona
                                </Option>
                                <Option value="TO_FIX">
                                  <span className="dot red" />
                                  Do naprawy
                                </Option>
                                <Option value="REJECTED">
                                  <span className="dot blue" />
                                  Odrzucona
                                </Option>
                                <Option value="DONE">
                                  <span className="dot green" />
                                  Zakończona
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="addDate"
                            >
                              <DatePicker size="large" placeholder="Data dodania" style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="fixDate"
                            >
                              <DatePicker size="large" placeholder="Usunięto" style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      )}
                      {!isClient && (
                        <div className="form-section">
                          <h2>Osoba odpowiedzialna</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name="responsibleWorkers"
                              >
                                <SearchBox
                                  placeholder="Osoba odpowiedzialna"
                                  size="large"
                                  resource={workersService}
                                  filters={{ role: 'ROLE_WORKER' }}
                                  resourceField="firstOrLastName"
                                  optionValue={(e) => getUserOptionLabel(e)}
                                  mode="multiple"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Card>
              <Divider />
              <Row className="form-actions" justify="space-between">
                <Col sm={4} className="form-actions-cancel-wrapper">
                  <Button className="button-secondary" size="large" onClick={handleBack}>
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className="form-actions-save-wrapper">
                  <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                      Zapisz
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
